import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const P4Footer = (props) => {
  return (
    <MDBFooter className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left" style={props.style}>
        <MDBRow>
          <MDBCol md="6">
            <h5 className="title">株式会社P4NEXT</h5>
            <address>
            
            〒330-0052　埼玉県さいたま市浦和区本太1-13-10　N302<br />
            https://p4next.co.jp/
            </address>
          </MDBCol>
          <MDBCol md="6">
            <ul>
              <li className="list-unstyled">
                <a href="#!">サイトポリシー</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">ご利用規約</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">個人情報保護方針</a>
              </li>
              <li className="list-unstyled">
                <a href="#!">サポート</a>
              </li>
              <li className="list-unstyled">
                <img src="/search-by-algolia-dark-background.svg" />
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3" style={props.style2}>
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://p4next.co.jp/"> P4NEXT </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default P4Footer;