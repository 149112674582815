import React from "react";
import { withRouter } from 'react-router-dom'
import {Layout, Button, Dropdown, Space, AutoComplete, Flex, Input, Badge} from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    MailOutlined,
    UserOutlined
} from '@ant-design/icons';
import {MDBBadge, MDBDropdownItem} from "mdbreact";

const { Header } = Layout;

const Title = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
        <a href="https://www.google.com/search?q=antd" target="_blank" rel="noopener noreferrer">
            more
        </a>
    </Flex>
);

class P4Header1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            organization: null,
            company: null,
            seller: null,
            email: null,
            seller_company: null,
            organizationUnread: {},
            unread: 0,
            unreadNotShipped: 0,
            sellerOrganizations: [],
            buyerOrganizations: [],
            sellerOrg: null,
        }
    }

    sumObjectsByKey = (...objs) => {
        return objs.reduce((a, b) => {
            for (let k in b) {
                if (b.hasOwnProperty(k))
                    a[k] = (a[k] || 0) + b[k];
            }
            return a;
        }, {});
    }

    componentDidMount() {
        this.organizationCheck();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((nextProps.orgSeller && nextProps.orgSeller.id) !== this.state.sellerOrg) {
            this.setState({
                sellerOrg: nextProps.orgSeller.id
            }, () => {
                this.selectSellerOrganization(nextProps.orgSeller, true);
            })
        }

        this.setState({
            organizationUnread: this.sumObjectsByKey(
                nextProps.unreadOrderWithOrg,
                nextProps.unreadBillWithOrg,
                nextProps.unreadDeliveryNotesWithOrg,
                nextProps.unreadEstimates2WithOrg,
                nextProps.unreadEstimatesWithOrg,
                nextProps.unreadReportWithOrg,
                nextProps.unreadOCHWithOrg,
                // nextProps.sdsWaitingCountWithOrg
            ),
            unread: (
                nextProps.unreadOrder+
                nextProps.unreadBill+
                nextProps.unreadDeliveryNotes+
                nextProps.unreadEstimates2+
                nextProps.unreadEstimates+
                nextProps.unreadReport+
                nextProps.unreadOCH
                // nextProps.sdsWaitingCount
            ),
            unreadNotShipped: nextProps.notShippedOrder
        }, () => {
            // console.log(this.state.organizationUnread)
        })
    }

    selectSellerOrganization = (seller, initial) => {
        this.props.orgSelectSeller(seller.id, (seller.company_name || '') + ' ' + seller.name, initial);
        this.setState({
            buyerOrganizations: seller.buyers || []
        })
    }

    organizationCheck = () => {
        if(this.props.user && this.props.user.organization){
            let organizationRef = this.props.db.collection('organizations').doc(this.props.user.organization);
            let getDoc = organizationRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such organization document!')
                    } else {
                        if ( doc.data().active ) {
                            let org = doc.data();
                            org.id = doc.id;

                            this.setState({
                                organization: org,
                            });

                            this.companyCheck();
                            this.sellerCheck();
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting organization document', this.props.user.organization, err)
                })
        } else if (this.props.user && this.props.user.admin) {
            this.adminUserList()
                .then((snapshot) => {
                    if (snapshot.length > 0) {
                        let organizations = [];
                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data.id = doc.id;
                            organizations.push(data)
                        });

                        this.setState({
                            sellerOrganizations: organizations,
                            buyerOrganizations: []
                        })
                    } else {
                        console.log('No such organization document!')
                    }
                })
        }

    }

    adminUserList = async () => {
        const seller = this.props.db.collection('organizations').where('reseller', '==', true).get();
        const maker = this.props.db.collection('organizations').where('maker', '==', true).get();

        const [
            sellerSnapshot,
            makerSnapshot
        ] = await Promise.all([seller, maker]);

        const sellerArray = sellerSnapshot.docs || [];
        const makerArray = makerSnapshot.docs || [];

        //Note that we don't need to de-duplicate in this case
        return sellerArray.concat(makerArray);
    }

    companyCheck = () => {
        if(this.state.organization){
            let companyRef = this.props.db.collection('company').doc(this.state.organization.company);
            let getDoc = companyRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such company document!')
                    } else {
                        if ( doc.data().active ) {
                            let company = doc.data();
                            company.id = doc.id;
                            this.setState({
                                company: company,
                            });
                            let org = this.state.organization;
                            org.display_name = company.name + ' ' + org.name;
                            console.log('org', org.display_name)

                            this.props.setupOrgAndCompany(org);
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting company document', err)
                })
        }
    }

    sellerCheck = () => {
        if(this.state.organization && this.state.organization.seller){
            let sellerRef = this.props.db.collection('organizations').doc(this.state.organization.seller);
            let getDoc = sellerRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such seller document!')
                    } else {
                        if ( doc.data().active ) {
                            let seller = doc.data();
                            seller.id = doc.id;
                            this.setState({
                                seller: seller,
                            });
                            // console.log('seller', seller)
                            this.seller_companyCheck();
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting seller document', err)
                })
        }
    }

    seller_companyCheck = () => {
        if(this.state.organization){
            let companyRef = this.props.db.collection('company').doc(this.state.seller.company);
            let getDoc = companyRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such seller_company document!')
                    } else {
                        if ( doc.data().active ) {
                            this.setState({
                                seller_company: doc.data(),
                            });
                            this.props.orgSelectData(this.state.seller.id, this.state.seller_company.name + ' ' + this.state.seller.name);
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting seller_company document', err)
                })
        }
    }

    handleMenuClick = (e) => {
        switch (e.key) {
            case '1':
                this.props.history.push('/emailsettings')
                break;
            case '2':
                this.props.logout();
                break;
        }
    }

    selectOrg = (value, option) => {
        this.setState({
            activeOrg: {
                organization: option.id,
                name: option.name
            }
        });
        this.props.orgSelect(option.id, option.name);
    }

    render() {
        const renderItem = (title, count) => ({
            value: title,
            label: (
                <Flex align="center" justify="space-between">
                    {title}
                    <span>
                        <UserOutlined /> {count}
                    </span>
                </Flex>
            ),
        });

        // console.log('this.state.organization', this.state.organization, this.state.sellerOrganizations, this.state.buyerOrganizations)

        let orgList = [];

        let sellerOrganizations = this.state.sellerOrganizations;
        if (this.state.searchOrg) {
            sellerOrganizations = this.state.filteredOrg;
        }

        let buyerOrganizations = this.state.buyerOrganizations;
        if (this.state.searchOrg1) {
            buyerOrganizations = this.state.filteredOrg1;
        }

        // buyerOrganizations.map((buyer, i) => {
        //     // console.log('this.props.sdsNormalCountWithOrg[buyer.organization]', buyer);
        //     orgList.push({
        //         id: buyer.organization,
        //         value: `[仕入側] ${buyer.name}`,
        //         label: <span><Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyer.organization] || 0} showZero={false}/>[仕入側] {buyer.name} <Badge color={'green'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyer.organization] || 0} showZero={false}/></span>,
        //         name: buyer.name,
        //         sdsNormal: this.props.sdsNormalCountWithOrg[buyer.organization],
        //         sdsWarning: this.props.sdsWarningCountWithOrg[buyer.organization],
        //         sdsAlert: this.props.sdsAlertCountWithOrg[buyer.organization],
        //         sdsWait: this.props.sdsWaitingCountWithOrg[buyer.organization]
        //     })
        // });

        let buyersOrganizations = this.state.organization && this.state.organization.buyers;
        if (this.state.searchOrgBuyer || this.state.searchOrgBuyerSeller) {
            buyersOrganizations = this.state.filteredOrgBuyer;
        }

        // buyersOrganizations && buyersOrganizations.map((buyer, i) => {
        //     // console.log('this.props.sdsNormalCountWithOrg[buyer.organization]', buyer);
        //     orgList.push({
        //         id: buyer.organization,
        //         value: `[仕入側] ${buyer.name}`,
        //         label: <span style={{display: 'flex', gap: 5}}>
        //             <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyer.organization] || 0} showZero={false}/>
        //             [仕入側] {buyer.name}
        //             <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //         </span>,
        //         name: buyer.name,
        //         sdsNormal: this.props.sdsNormalCountWithOrg[buyer.organization],
        //         sdsWarning: this.props.sdsWarningCountWithOrg[buyer.organization],
        //         sdsAlert: this.props.sdsAlertCountWithOrg[buyer.organization],
        //         sdsWait: this.props.sdsWaitingCountWithOrg[buyer.organization]
        //     })
        // });

        let sellersOrganizations = this.state.organization && this.state.organization.sellers;
        if (this.state.searchOrgSeller || this.state.searchOrgBuyerSeller) {
            sellersOrganizations = this.state.filteredOrgSeller;
        }

        let customersOrganizations = this.state.organization && this.state.organization.customers;
        if (this.state.searchOrgCustomer) {
            customersOrganizations = this.state.filteredOrgCustomer;
        }

        console.log('this.props.org.display_name', this.props.org, this.state.organization);

        if (this.state.organization && this.state.organization.administrator) {
            customersOrganizations.map((customers, i) => {
                orgList.push({
                    id: customers.organization,
                    value: `${customers.name}`,
                    label: <span style={{display: 'flex', gap: 5}}>
                        <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[customers.organization] || 0} showZero={false}/>
                        {customers.name}
                        <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[customers.organization] || 0} showZero={false}/>
                        <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[customers.organization] || 0} showZero={false}/>
                        <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[customers.organization] || 0} showZero={false}/>
                        <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[customers.organization] || 0} showZero={false}/>
                        <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[customers.organization] || 0} showZero={false}/>
                    </span>,
                    name: customers.name,
                    organizationUnread: this.state.organizationUnread[customers.organization] || 0,
                    notShippedOrder: this.props.sdsNormalCountWithOrg[customers.organization] || 0,
                    sdsNormal: this.props.sdsNormalCountWithOrg[customers.organization] || 0,
                    sdsWarning: this.props.sdsWarningCountWithOrg[customers.organization] || 0,
                    sdsAlert: this.props.sdsAlertCountWithOrg[customers.organization] || 0,
                    sdsWait: this.props.sdsWaitingCountWithOrg[customers.organization] || 0
                });
            });
        }

        if (this.state.organization && this.state.organization.reseller) {
            if (buyersOrganizations) {
                buyersOrganizations.map((buyers, i) => {
                    orgList.push({
                        id: buyers.organization,
                        value: `[売先] ${buyers.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                        <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyers.organization] || 0} showZero={false}/>
                        [売先] {buyers.name}
                            <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyers.organization] || 0} showZero={false}/>
                    </span>,
                        name: buyers.name,
                        organizationUnread: this.state.organizationUnread[buyers.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[buyers.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[buyers.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[buyers.organization] || 0
                    });
                });
            }

            if (sellersOrganizations) {
                sellersOrganizations.map((seller, i) => {
                    orgList.push({
                        id: seller.organization,
                        value: `[売先] ${seller.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                        <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[seller.organization] || 0} showZero={false}/>
                        [仕入先] {seller.name}
                        <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[seller.organization] || 0} showZero={false}/>
                        <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[seller.organization] || 0} showZero={false}/>
                        <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[seller.organization] || 0} showZero={false}/>
                        <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[seller.organization] || 0} showZero={false}/>
                        <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[seller.organization] || 0} showZero={false}/>
                    </span>,
                        name: seller.name,
                        organizationUnread: this.state.organizationUnread[seller.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[seller.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[seller.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[seller.organization] || 0
                    });
                });
            }
        }

        if (this.state.organization && this.state.organization.maker) {
            if (buyersOrganizations) {
                buyersOrganizations.map((buyers, i) => {
                    orgList.push({
                        id: buyers.organization,
                        value: `[売先] ${buyers.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                        <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyers.organization] || 0} showZero={false}/>
                        [売先] {buyers.name}
                            <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyers.organization] || 0} showZero={false}/>
                        <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyers.organization] || 0} showZero={false}/>
                    </span>,
                        name: buyers.name,
                        organizationUnread: this.state.organizationUnread[buyers.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[buyers.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[buyers.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[buyers.organization] || 0
                    });
                });
            }
        }

        return (<>
            <Header
                style={{
                    padding: 0,
                    background: '#ffffff',
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    zIndex: 10
                }}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        type="text"
                        icon={this.props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => this.props.setCollapsed(!this.props.collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <AutoComplete
                        popupClassName={'certain-category-search-dropdown'}
                        popupMatchSelectWidth={500}
                        style={{ width: 250 }}
                        value={this.props.org && this.props.org.display_name}
                        options={orgList}
                        size={'large'}
                        // onSearch={(text) => this.setState({searchOrg: text})}
                        onSelect={(value, option) => this.selectOrg(value, option)}
                    >
                        <Input.Search size={'large'} placeholder={'input here'}/>
                    </AutoComplete>
                </div>

                <div style={{padding: '0 15px'}}>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (<div style={{display: 'flex', flexDirection: 'column', padding: 20, gap: 5, alignItems: 'center'}}>
                                        <span>ユーザー情報</span>
                                        <span>{this.props.user.last_name} {this.props.user.first_name}</span>
                                        <span>{this.state.company ? this.state.company.name : ''}</span>
                                        <span>{this.state.organization ? this.state.organization.name : ''}</span>
                                    </div>),
                                    key: '0',
                                },
                                {
                                    label: (<span style={{display: 'flex', gap: 5}}><MailOutlined /> 通知メール受信設定</span>),
                                    key: '1'
                                },
                                {
                                    type: 'divider',
                                },
                                {
                                    label: 'Logout',
                                    key: '2',
                                },
                            ],
                            onClick: this.handleMenuClick
                        }}
                        trigger={['click']}
                        style={{margin: 20}}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {this.props.user.last_name} {this.props.user.first_name}
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </Header>
        </>)
    }
}

export default withRouter(P4Header1);
