import React from 'react';
import firebase from '../../firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBInput } from 'mdbreact';
import "./SignInScreenP4.css";

class SignInScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      message: "",
    };
  }

  changeMail = (e) => {
    this.setState({email: e.target.value});
  }
  changePass = (e) => {
    this.setState({password: e.target.value});
  }

  handleSignIn = () => {
    this.setState({message: ""});

    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch( error =>  {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      var errorMessageJa = this.errorToJa(error);
      //alert(errorCode);
      //alert(errorMessageJa);
      console.log('errorMessageJa', errorMessageJa)
      this.setState({message: errorMessageJa});
    });

  };

  errorToJa = (e) => {
    switch (e.code) {
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
          return null;
      case 'auth/email-already-in-use':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/invalid-email':
        return 'メールアドレスの形式が正しくありません';
      case 'auth/user-disabled':
        return 'サービスの利用が停止されています';
      case 'auth/user-not-found':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/user-mismatch':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/weak-password':
        return 'パスワードは6文字以上にしてください';
      case 'auth/wrong-password':
        return 'メールアドレスまたはパスワードが違います';
      case 'auth/popup-blocked':
        return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください';
      case 'auth/operation-not-supported-in-this-environment':
      case 'auth/auth-domain-config-required':
      case 'auth/operation-not-allowed':
      case 'auth/unauthorized-domain':
        return '現在この認証方法はご利用頂けません';
      case 'auth/requires-recent-login':
        return '認証の有効期限が切れています';
      default:
        return 'エラーが発生しました。しばらく時間をおいてお試しください';
    }
  }

  render() {
    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="4">
            <MDBCard className="form-simple mt-5">
              <div className="header pt-3 ">
                <MDBRow className="d-flex justify-content-start">
                  <h3 className="mt-3 mb-4 pb-1 mx-5">
                    メールでログイン
                  </h3>
                </MDBRow>
              </div>
              <MDBCardBody className="mx-4 mt-4">
                <MDBInput label="メールアドレス" group type="text" validate onChange={this.changeMail} />
                <MDBInput
                  label="パスワード"
                  group
                  type="password"
                  validate
                  containerClass="mb-0"
                  onChange={this.changePass}
                />
                <div className="mb-4 mt-5 text-danger">
                  {this.state.message}
                </div>
                <div className="text-center mb-4 mt-5">
                  <MDBBtn
                    color="default"
                    type="button"
                    className="btn-block z-depth-2"
                    onClick={this.handleSignIn}
                  >
                    サインイン
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
            <span className="uiVersion">20250127</span>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default SignInScreen;
