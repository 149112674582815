import React from "react";
import { withRouter, NavLink } from 'react-router-dom'
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBSideNavLink, MDBNavLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBSideNavItem, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBContainer, MDBBadge } from "mdbreact";
import "./P4Header.css";
import { Button, Layout, Menu, theme, Badge } from 'antd';
import {
  BankOutlined,
  BarChartOutlined, LineChartOutlined, StarOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
const { Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'sticky',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarGutter: 'stable',
};

class DoubleNavigationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
      organization: null,
      company: null,
      seller: null,
      email: null,
      seller_company: null,
      organizationUnread: {},
      unread: 0,
      unreadNotShipped: 0,
      sellerOrganizations: [],
      buyerOrganizations: [],
      sellerOrg: null,
      buyerOrg: null,
      searchOrg: '',
      filteredOrg: [],

      searchOrg1: '',
      filteredOrg1: [],

      searchOrgSeller: '',
      filteredOrgSeller: [],

      searchOrgBuyer: '',
      filteredOrgBuyer: [],

      searchOrgCustomer: '',
      filteredOrgCustomer: [],

      searchOrgBuyerSeller: '',

      activeOrg: {
        organization: '',
        name: ''
      },

      activeOrg1: {
        id: '',
        company_name: '',
        name: ''
      },
      current: 37
    };
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA
    });
  };

  organizationCheck = () => {
    if(this.props.user && this.props.user.organization){

      let organizationRef = this.props.db.collection('organizations').doc(this.props.user.organization);
      let getDoc = organizationRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such organization document!')
          } else {
            if ( doc.data().active ) {
              let org = doc.data();
              org.id = doc.id;

              console.log('org list: ', this.props.user.organization, org)
              this.setState({
                organization: org,
              });
             //this.props.setupOrgAndCompany(org);

              this.companyCheck();
              this.sellerCheck();

            }
          }
        })
        .catch(err => {
          console.log('Error getting organization document', this.props.user.organization, err)
        })
    } else if (this.props.user && this.props.user.admin) {
      // console.log('organization check');

      this.adminUserList()
          .then((snapshot) => {
            // console.log('organizations',snapshot)
            // snapshot.forEach((doc) => {
            //   console.log(doc.data())
            // })
            // console.log('organization length:',snapshot.length)
            if (snapshot.length > 0) {
              let organizations = [];
              snapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                organizations.push(data)
              });

              // console.log(organizations)

              this.setState({
                sellerOrganizations: organizations,
                buyerOrganizations: []
              })
            } else {
              console.log('No such organization document!')
            }
          })
    }

  }

  adminUserList = async () => {
    const seller = this.props.db.collection('organizations').where('reseller', '==', true).get();
    const maker = this.props.db.collection('organizations').where('maker', '==', true).get();

    const [
      sellerSnapshot,
      makerSnapshot
    ] = await Promise.all([seller, maker]);

    const sellerArray = sellerSnapshot.docs || [];
    const makerArray = makerSnapshot.docs || [];

    //Note that we don't need to de-duplicate in this case
    return sellerArray.concat(makerArray);
  }

  companyCheck = () => {
    if(this.state.organization){

      let companyRef = this.props.db.collection('company').doc(this.state.organization.company);
      let getDoc = companyRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such company document!')
          } else {
            if ( doc.data().active ) {
              let company = doc.data();
              company.id = doc.id;
              this.setState({
                company: company,
              });
              let org = this.state.organization;
              org.display_name = company.name + ' ' + org.name;
              console.log('org', org.display_name)

              this.props.setupOrgAndCompany(org);
            }
          }
        })
        .catch(err => {
          console.log('Error getting company document', err)
        })
    }

  }



  sellerCheck = () => {
    if(this.state.organization && this.state.organization.seller){

      let sellerRef = this.props.db.collection('organizations').doc(this.state.organization.seller);
      let getDoc = sellerRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such seller document!')
          } else {
            if ( doc.data().active ) {
              let seller = doc.data();
              seller.id = doc.id;
              this.setState({
                seller: seller,
              });
              // console.log('seller', seller)
              this.seller_companyCheck();
            }
          }
        })
        .catch(err => {
          console.log('Error getting seller document', err)
        })
    }

  }

  seller_companyCheck = () => {
    if(this.state.organization){

      let companyRef = this.props.db.collection('company').doc(this.state.seller.company);
      let getDoc = companyRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such seller_company document!')
          } else {
            if ( doc.data().active ) {
              this.setState({
                seller_company: doc.data(),
              });
              this.props.orgSelectData(this.state.seller.id, this.state.seller_company.name + ' ' + this.state.seller.name);
            }
          }
        })
        .catch(err => {
          console.log('Error getting seller_company document', err)
        })
    }

  }


  componentDidMount() {
    this.organizationCheck();
  }

  sumObjectsByKey = (...objs) => {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k))
          a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  }

  // componentWillReceiveProps()

  componentWillReceiveProps(nextProps, nextContext) {
    // this.props.unreadOrderWithOrg[customers.organization]
    // console.log('p4header', nextProps.location.pathname)

    // console.log(nextProps.orgSeller && nextProps.orgSeller.id, this.props.orgSeller && this.props.orgSeller.id);
    if ((nextProps.orgSeller && nextProps.orgSeller.id) !== this.state.sellerOrg) {
      // console.log('change org');
      this.setState({
        sellerOrg: nextProps.orgSeller.id
      }, () => {
        this.selectSellerOrganization(nextProps.orgSeller, true);
      })
    }

    // if (nextProps.width !== this.props.width && !this.state.mainToggle) {
    //   console.log('componentWillReceiveProps', this.props.width, this.props.breakWidth);
    //   if (this.props.width < this.props.breakWidth) {
    //     this.setState({
    //       mainToggle: true
    //     }, () => {
    //       this.props.mainToggle(this.state.mainToggle);
    //     })
    //   }
    // }

    this.setState({
      organizationUnread: this.sumObjectsByKey(
          nextProps.unreadOrderWithOrg,
          nextProps.unreadBillWithOrg,
          nextProps.unreadDeliveryNotesWithOrg,
          nextProps.unreadEstimates2WithOrg,
          nextProps.unreadEstimatesWithOrg,
          nextProps.unreadReportWithOrg,
          nextProps.unreadOCHWithOrg,
          // nextProps.sdsWaitingCountWithOrg
      ),
      unread: (
          nextProps.unreadOrder+
          nextProps.unreadBill+
          nextProps.unreadDeliveryNotes+
          nextProps.unreadEstimates2+
          nextProps.unreadEstimates+
          nextProps.unreadReport+
          nextProps.unreadOCH
          // nextProps.sdsWaitingCount
      ),
      unreadNotShipped: nextProps.notShippedOrder
    }, () => {
      // console.log(this.state.organizationUnread)
    })

  }

  selectSellerOrganization = (seller, initial) => {
    // console.log('buyers',seller.buyers);
    this.props.orgSelectSeller(seller.id, (seller.company_name || '') + ' ' + seller.name, initial);
    this.setState({
      buyerOrganizations: seller.buyers || []
    })
  }

  adminBadgeCount = (sellerId, buyerId, type) => {
    // {this.props.sdsNormalCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsWarningCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsAlertCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsWaitingCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[customers.organization]}</MDBBadge>}

    switch (type) {
      case 'waiting':
        console.log(this.props.sdsWaitingCountWithOrg[buyerId])
        return 0
      case 'normal': return 0;
      case 'warning': return 0;
      case 'alert': return 0;
      default:
        return 0
    }
  }

  handleChange = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.sellerOrganizations.filter(o => {
        let name = o.name && o.name.toLowerCase();
        let company_name = o.company_name && o.company_name.toLowerCase();

        return (name && name.includes(value)) || (company_name && company_name.includes(value))
      });
    }

    this.setState({searchOrg: event.target.value, filteredOrg: result});
  }

  handleChange1 = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.buyerOrganizations.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    console.log('result',result)

    this.setState({searchOrg1: event.target.value, filteredOrg1: result});
  }

  handleChangeCustomer = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.customers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgCustomer: event.target.value, filteredOrgCustomer: result});
  }

  handleChangeBuyers = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.buyers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgBuyer: event.target.value, filteredOrgBuyer: result});
  }

  handleChangeSeller = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.sellers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgSeller: event.target.value, filteredOrgSeller: result});
  }

  handleChangeBuyerSeller = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let resultBuyer = [];
    let resultSeller = [];
    if (value) {
      value = this.toASCII(value);
      resultSeller = this.state.organization && this.state.organization.sellers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })

      resultBuyer = this.state.organization && this.state.organization.buyers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgBuyerSeller: event.target.value, filteredOrgSeller: resultSeller, filteredOrgBuyer: resultBuyer});
  }

  handleKeyDown1 = (event, organizations1) => {
    // console.log(event.key, organizations1);

    if (event.key === 'Tab' || (event.shiftKey && event.key === 'Tab') || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();

      let findIndex1 = organizations1.findIndex(org => {
        return org.id === this.state.activeOrg1.id
      });

      // console.log(findIndex1)

      if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
        if (findIndex1 > 0) {
          this.setState({
            activeOrg1: organizations1[findIndex1-1] || {
              id: '',
              name: '',
            }
          });
        }
      } else {
        if (findIndex1 === -1) {
          this.setState({
            activeOrg1: organizations1[0] || {
              id: '',
              name: ''
            }
          });
        } else {
          if (findIndex1+1 !== organizations1.length) {
            this.setState({
              activeOrg1: organizations1[findIndex1+1] || {
                id: '',
                name: ''
              }
            });
          }

        }
      }
    }

    let access = document.getElementById(this.state.activeOrg1.id);
    if (access) {
      access.scrollIntoView({block: "center"});
    }

    if (event.key === 'Enter') {
      this.mdbToggle1.context.toggle();

      this.selectSellerOrganization(this.state.activeOrg1, false);
    }
  }

  handleKeyDown = (event, organizations1, organizations2) => {
    // console.log(event.key, organizations1, organizations2);

    if (event.key === 'Tab' || (event.shiftKey && event.key === 'Tab') || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();

      let findIndex1 = organizations1.findIndex(org => {
        return org.organization === this.state.activeOrg.organization
      });

      let findIndex2 = organizations2.findIndex(org => {
        return org.organization === this.state.activeOrg.organization
      });

      // console.log(findIndex1, findIndex2)

      if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
        if (findIndex1 > 0) {
          this.setState({
            activeOrg: organizations1[findIndex1-1] || {
              organization: '',
              name: ''
            }
          });
        }

        if (findIndex2 > 0) {
          this.setState({
            activeOrg: organizations2[findIndex2-1] || {
              organization: '',
              name: ''
            }
          });
        } else if (findIndex2 === 0) {
          this.setState({
            activeOrg: organizations1[organizations1.length-1] || {
              organization: '',
              name: ''
            }
          });
        }
      } else {
        if (findIndex1 === -1 && findIndex2 === -1) {
          this.setState({
            activeOrg: organizations1[0] || {
              organization: '',
              name: ''
            }
          });
        } else {
          if (findIndex1 >= 0) {
            if (findIndex1+1 === organizations1.length) {
              if (organizations2.length > 0) {
                this.setState({
                  activeOrg: organizations2[0] || {
                    organization: '',
                    name: ''
                  }
                });
              }
            } else {
              this.setState({
                activeOrg: organizations1[findIndex1+1] || {
                  organization: '',
                  name: ''
                }
              });
            }
          }

          if (findIndex2 >= 0) {
            if (findIndex2+1 !== organizations2.length) {
              this.setState({
                activeOrg: organizations2[findIndex2+1] || {
                  organization: '',
                  name: ''
                }
              });
            }
          }

        }
      }
    }

    let access = document.getElementById(this.state.activeOrg.organization);
    if (access) {
      access.scrollIntoView({block: "center"});
    }

    if (event.key === 'Enter') {
      // console.log(this.mdbToggle)
      this.mdbToggle.context.toggle();
      this.props.orgSelect({
        preventDefault: () => {},
        currentTarget: {
          dataset: {
            org: this.state.activeOrg.organization,
            name: this.state.activeOrg.name
          }
        }
      })
    }
  }

  toASCII = (chars) => {
    let ascii = '';
    for(let i=0, l=chars.length; i<l; i++) {
      let c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xFF00 && c <= 0xFFEF) {
        c = 0xFF & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
    }

    return ascii;
  }

  setCollapse = (collapse) => {
    this.setState({collapse: collapse})
  }

  render() {
    //console.log(nextProps.user);
    let navStyle = {
      paddingLeft:
        this.props.width > this.props.breakWidth ? "210px" : "16px"
    };
    if (this.state.organization && this.state.organization.maker){
      navStyle = {
        paddingLeft:
          this.props.width > this.props.breakWidth ? "210px" : "16px",
        backgroundColor:
          "#b71c1c"
      };
    }

    if (this.props.location.pathname.startsWith('/measurement')) {
      navStyle = {
        paddingLeft:
            this.props.width > this.props.breakWidth ? "210px" : "16px",
        backgroundColor:
            "#5E5E5E"
      };
    }

    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row"
    };

    let itemCatName="商品購入"
    if (this.state.organization && this.state.organization.maker ){
      itemCatName="商品一覧"
    }

    let menuKoutei = <></>
      // <MDBSideNavCat
      //   name="工程エリア管理"
      //   id="user-cat"
      // >
      //   <MDBSideNavItem>サブメニュー</MDBSideNavItem>
      //   <MDBSideNavItem>サブメニュー</MDBSideNavItem>
      // </MDBSideNavCat>
    if(this.state.organization && this.state.organization.reseller){
      menuKoutei = <></>
    }
    if(this.state.organization && this.state.organization.maker){
      menuKoutei = <></>
    }

    let iconReport = '';
    let customClassReport = '';
    if (this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) {
      iconReport = 'bell';
      customClassReport = 'menuWithNotif'
    }

    let iconEstimates2 = '';
    let customClassEstimates2 = '';
    if (this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) {
      iconEstimates2 = 'bell';
      customClassEstimates2 = 'menuWithNotif'
    }

    let iconEstimates = '';
    let customClassEstimates = '';
    if (this.props.org && this.props.unreadEstimatesWithOrg[this.props.org.id]) {
      iconEstimates = 'bell';
      customClassEstimates = 'menuWithNotif'
    }

    let iconBill = '';
    let customClassBill = '';
    if (this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) {
      iconBill = 'bell';
      customClassBill = 'menuWithNotif'
    }

    let iconDeliveryNotes = '';
    let customClassDeliveryNotes = '';
    if (this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) {
      iconDeliveryNotes = 'bell';
      customClassDeliveryNotes = 'menuWithNotif'
    }

    let iconSDS = '';
    let customClassSDS = '';
    if (this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) {
      iconSDS = 'bell';
      customClassSDS = 'menuWithNotif'
    }

    let icon = '';
    let customClass = '';
    if (this.props.org && this.props.unreadOrderWithOrg[this.props.org.id]) {
      // console.log('org',this.props.org);
      icon = this.props.unreadOrder > 0 ? "bell" : "";
      customClass = this.props.unreadOrder > 0 ? "menuWithNotif" : "";
    }

    let iconOCH = 'shopping-cart';
    let customClassOCH = '';
    if (this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) {
      iconOCH = this.props.unreadOCH > 0 ? "bell": "shopping-cart";
      customClassOCH = this.props.unreadOCH > 0 ? "menuWithNotif" : "";
    }

    // let menuOtasukeCart = <MDBSideNavCat
    //     name="お助けカート"
    //     id="user-cat"
    //     icon="shopping-cart"
    // >
    //   <MDBSideNavLink to='/otasuke/carts'>お助けカート一覧</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/1'><MDBIcon icon="shopping-cart" />お助けカート1</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/2'><MDBIcon icon="shopping-cart" />お助けカート2</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/3'><MDBIcon icon="shopping-cart" />お助けカート3</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/4'><MDBIcon icon="shopping-cart" />お助けカート4</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/5'><MDBIcon icon="shopping-cart" />お助けカート5</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート送信履歴</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuOtasukeCart = <Menu.SubMenu title={<span>
       <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
           d="M440-600v-120H320v-80h120v-120h80v120h120v80H520v120h-80ZM280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM40-800v-80h131l170 360h280l156-280h91L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68.5-39t-1.5-79l54-98-144-304H40Z"/></svg>
        <span style={{marginLeft: 10}}>お助けカート</span>
      </span>}>
      <Menu.Item key={'116'}>
        <NavLink to='/otasuke/carts'>
          <span>お助けカート一覧</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={'115'}>
        <NavLink to='/otasuke/cart/1'><MDBIcon icon="shopping-cart"/>お助けカート1</NavLink>
      </Menu.Item>
      <Menu.Item key={'114'}>
        <NavLink to='/otasuke/cart/2'><MDBIcon icon="shopping-cart"/>お助けカート2</NavLink>
      </Menu.Item>
      <Menu.Item key={'113'}>
        <NavLink to='/otasuke/cart/3'><MDBIcon icon="shopping-cart" />お助けカート3</NavLink>
      </Menu.Item>
      <Menu.Item key={'112'}>
        <NavLink to='/otasuke/cart/4'><MDBIcon icon="shopping-cart" />お助けカート4</NavLink>
      </Menu.Item>
      <Menu.Item key={'111'}>
        <NavLink to='/otasuke/cart/5'><MDBIcon icon="shopping-cart" />お助けカート5</NavLink>
      </Menu.Item>
      <Menu.Item key={'110'}>
        <NavLink to='/otasuke/history/carts'>お助けカート送信履歴</NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if (this.state.organization && this.state.organization.buyer){
      // menuOtasukeCart = <MDBSideNavCat
      //     name="お助けカート"
      //     id="user-cat"
      //     // icon="shopping-cart"
      //     className={customClassOCH}
      //     icon={iconOCH}
      // >
      //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 {this.props.org && this.props.unreadOCHWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOCHWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
      // </MDBSideNavCat>

      menuOtasukeCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>お助けカート</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
        <Menu.Item key={'109'}>
          <NavLink to='/otasuke/history/carts'>お助けカート一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
        </Menu.Item>
      </Menu.SubMenu>
    }

    if (this.state.organization && this.state.organization.reseller) {
      if (this.props.org && this.props.org.maker) {
        // menuOtasukeCart = <MDBSideNavCat
        //     name="お助けカート"
        //     id="user-cat"
        //     className={customClassOCH}
        //     icon={iconOCH}
        // >
        //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 {this.props.org && this.props.unreadOCHWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOCHWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
        // </MDBSideNavCat>

        menuOtasukeCart = <Menu.SubMenu title={<span>
          <UserOutlined />
          <span>お助けカート</span>
        </span>}>
          <Menu.Item key={'108'}>
            <NavLink to='/otasuke/history/carts'>お助けカート一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOCHWithOrg[this.props.org.id] || 0} showZero={false}/></NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      }
    }

    // let menuCart = <MDBSideNavCat
    //   name="カート"
    //   id="user-cat"
    //   icon="shopping-cart"
    // >
    //   <MDBSideNavLink to='/cart/1'><MDBIcon icon="shopping-cart" />カート1</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/2'><MDBIcon icon="shopping-cart" />カート2</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/3'><MDBIcon icon="shopping-cart" />カート3</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/4'><MDBIcon icon="shopping-cart" />カート4</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/5'><MDBIcon icon="shopping-cart" />カート5</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>カート</span>
      </span>}>
      <Menu.Item key={'107'}>
        <NavLink to='/cart/1'><MDBIcon icon="shopping-cart" />カート1</NavLink>
      </Menu.Item>
      <Menu.Item key={'106'}>
        <NavLink to='/cart/2'><MDBIcon icon="shopping-cart" />カート2</NavLink>
      </Menu.Item>
      <Menu.Item key={'105'}>
        <NavLink to='/cart/3'><MDBIcon icon="shopping-cart" />カート3</NavLink>
      </Menu.Item>
      <Menu.Item key={'104'}>
        <NavLink to='/cart/4'><MDBIcon icon="shopping-cart" />カート4</NavLink>
      </Menu.Item>
      <Menu.Item key={'103'}>
        <NavLink to='/cart/5'><MDBIcon icon="shopping-cart" />カート5</NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if(this.state.organization && this.state.organization.maker){
      menuCart = <></>
    }

    if(this.state.organization && this.state.organization.reseller){
      if (this.props.org && this.props.org.maker){
      } else {
        menuCart = <></>
      }
    }

    // if (this.state.user)

    // let menuP4Bill = <MDBSideNavCat
    //   name="P4CLOUD請求書"
    //   id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.administrator && this.props.org &&
    //     <MDBSideNavLink to='/p4bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/p4bills'>請求書一覧</MDBSideNavLink>
    //
    // </MDBSideNavCat>

    let menuP4Bill = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>P4CLOUD請求書</span>
      </span>}>
      { this.state.organization && this.state.organization.administrator && this.props.org && <Menu.Item key={'101'}>
        <NavLink to='/p4bills/new'>請求書登録</NavLink>
      </Menu.Item> }
      <Menu.Item key={'102'}>
        <NavLink to='/p4bills'>請求書一覧</NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if(this.state.organization && this.state.organization.buyer){
      menuP4Bill = <></>
    }

    let menus = <></>
    if (this.props.user.admin) {

    } else {
      menus = <></>
    }

    // let menuStatistic = <MDBSideNavCat
    //     name="データ分析"
    //     id="ja nai"
    // >
    //   <>
    //     <MDBSideNavLink to='/statistic'>
    //       <p>購入データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></p>
    //     </MDBSideNavLink>
    //     {
    //       this.props.userOrg && this.props.userOrg.menuMeasurement && <MDBSideNavLink to='/measurement'>
    //           <span>測定データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></span>
    //         </MDBSideNavLink>
    //     }
    //
    //   </>
    // </MDBSideNavCat>

    let menuStatistic = <Menu.SubMenu title={<span>
      <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
          d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z"/></svg>
        <span style={{marginLeft: 10}}>購入データ</span>
      </span>}>
      <Menu.Item key={'1'}>
        <NavLink to='/statistic'>
          <span>購入データ<span style={{
            marginLeft: '5.5em',
            border: '1px solid',
            fontSize: '10px',
            padding: '3px',
            borderRadius: '5px'
          }}>ベータ版</span></span>
        </NavLink>
      </Menu.Item>
      {
          this.props.userOrg && this.props.userOrg.menuMeasurement && <Menu.Item key={'2'}>
            <NavLink to='/measurement'>
              <span>測定データ<span style={{ marginLeft: '5.5em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px'}}>ベータ版</span></span>
            </NavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>

    // let menuCompany = <MDBSideNavCat
    //     name="企業情報"
    //     id="company-cat"
    // >
    //   <MDBSideNavLink to='/comapnyinfo'>企業情報</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuCompany = <Menu.SubMenu title={<span>
     <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
         d="M80-120v-720h400v160h400v560H80Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h320v-400H480v80h80v80h-80v80h80v80h-80v80Zm160-240v-80h80v80h-80Zm0 160v-80h80v80h-80Z"/></svg>
        <span style={{marginLeft: 10}}>企業情報</span>
      </span>}>
      <Menu.Item key={'3'}>
        <NavLink to='/comapnyinfo'>
          <span>企業情報</span>
        </NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuUserInfo = <MDBSideNavCat
    //     name="取引先情報"
    //     id="user-cat"
    // >
    //   { (() => {
    //     if (this.state.organization && this.state.organization.buyer) {
    //       return <MDBSideNavLink to='/sellerinfo'>取引先情報</MDBSideNavLink>
    //     } else if (this.state.organization && this.state.organization.reseller) {
    //       if (this.props.org && this.props.org.maker ) {
    //         return <MDBSideNavLink to='/makerinfo'>取引先情報</MDBSideNavLink>
    //       } else {
    //         return <MDBSideNavLink to='/buyerinfo'>取引先情報</MDBSideNavLink>
    //       }
    //     } else if (this.state.organization && this.state.organization.maker) {
    //       return <MDBSideNavLink to='/buyerinfo'>取引先情報</MDBSideNavLink>
    //     }
    //   })() }
    // </MDBSideNavCat>

    let menuUserInfo = <Menu.SubMenu title={<span>
      <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
          d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
        <span style={{marginLeft: 10}}>取引先情報</span>
      </span>}>
      {(() => {
        if (this.state.organization && this.state.organization.buyer) {
          return <Menu.Item key={'4'}>
            <NavLink to='/sellerinfo'><span>取引先情報</span></NavLink>
          </Menu.Item>
        } else if (this.state.organization && this.state.organization.reseller) {
          if (this.props.org && this.props.org.maker) {
            return <Menu.Item key={'5'}>
              <NavLink to='/makerinfo'><span>取引先情報</span></NavLink>
            </Menu.Item>
          } else {
            return <Menu.Item key={'6'}>
              <NavLink to='/buyerinfo'><span>取引先情報</span></NavLink>
            </Menu.Item>
          }
        } else if (this.state.organization && this.state.organization.maker) {
          return <Menu.Item key={'7'}>
            <NavLink to='/buyerinfo'><span>取引先情報</span></NavLink>
          </Menu.Item>
        }
      })() }
    </Menu.SubMenu>

    // let menuItem = <MDBSideNavCat
    //     name={itemCatName} // 商品購入
    //     id="user-cat"
    // >
    //   <MDBSideNavLink to='/items'>商品一覧</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuItem = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:2}} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="M841-518v318q0 33-23.5 56.5T761-120H201q-33 0-56.5-23.5T121-200v-318q-23-21-35.5-54t-.5-72l42-136q8-26 28.5-43t47.5-17h556q27 0 47 16.5t29 43.5l42 136q12 39-.5 71T841-518Zm-272-42q27 0 41-18.5t11-41.5l-22-140h-78v148q0 21 14 36.5t34 15.5Zm-180 0q23 0 37.5-15.5T441-612v-148h-78l-22 140q-4 24 10.5 42t37.5 18Zm-178 0q18 0 31.5-13t16.5-33l22-154h-78l-40 134q-6 20 6.5 43t41.5 23Zm540 0q29 0 42-23t6-43l-42-134h-76l22 154q3 20 16.5 33t31.5 13ZM201-200h560v-282q-5 2-6.5 2H751q-27 0-47.5-9T663-518q-18 18-41 28t-49 10q-27 0-50.5-10T481-518q-17 18-39.5 28T393-480q-29 0-52.5-10T299-518q-21 21-41.5 29.5T211-480h-4.5q-2.5 0-5.5-2v282Zm560 0H201h560Z"/></svg>

      <span style={{marginLeft: 10}}>{itemCatName}</span>
      </span>}>
      <Menu.Item key={'8'}>
        <NavLink to='/items'>
          <span>商品一覧</span>
        </NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuFavoriteCart = <MDBSideNavCat
    //     name="お気に入り"
    //     id="user-cat"
    //     icon="star"
    // >
    //   <MDBSideNavLink to='/favorite/cart'>お気に入りセット一覧</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuFavoriteCart = <Menu.SubMenu title={<span>
        <StarOutlined />
        <span>お気に入り</span>
      </span>}>
      <Menu.Item key={'9'}>
        <NavLink to='/favorite/cart'>
          <span>お気に入りセット一覧</span>
        </NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuChumon = <MDBSideNavCat
    //     name="発注書一覧"
    //     id="user-cat"
    //     className={customClass}
    //     icon={icon}
    // >
    //   <MDBSideNavLink to='/order'>発注書一覧 {this.props.org && this.props.unreadOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOrderWithOrg[this.props.org.id]}</MDBBadge>} {this.props.org && this.props.notShippedOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>
    //
    // if( (this.state.organization && this.state.organization.maker )
    //     || (this.props.org && this.props.org.buyer && this.state.organization && this.state.organization.reseller ) ){
    //   // console.log('header maker')
    //   menuChumon = <MDBSideNavCat
    //       name="注文書一覧"
    //       id="user-cat"
    //       className={customClass}
    //       icon={icon}
    //   >
    //     <MDBSideNavLink to='/order'>注文書一覧 {this.props.org && this.props.unreadOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOrderWithOrg[this.props.org.id]}</MDBBadge>} {this.props.org && this.props.notShippedOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   </MDBSideNavCat>
    // }

    let menuChumon = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>発注書一覧</span>
        {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
      </span>}>
      <Menu.Item key={'10'}>
        <NavLink to='/order' style={{gap: 5}}>
          <span>発注書一覧</span>
          {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
          {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        </NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if( (this.state.organization && this.state.organization.maker )
        || (this.props.org && this.props.org.buyer && this.state.organization && this.state.organization.reseller ) ){
      // console.log('header maker')
      menuChumon = <Menu.SubMenu title={<span>
       <svg style={{marginBottom:2}} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
           d="m691-150 139-138-42-42-97 95-39-39-42 43 81 81ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-19-9-39-15t-41-9v-243H200v562h243q5 31 15.5 59T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Zm120-200h203q3-21 9-41t15-39H240v80Zm0-160h284q38-37 88.5-58.5T720-520H240v80Zm-40 242v-562 562Z"/></svg>
        <span style={{marginLeft: 10}} >注文書一覧</span>
        {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
                                  count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999}
                                  count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
      </span>}>
        <Menu.Item key={'10'}>
          <NavLink to='/order'>
            <span>注文書一覧</span>
            {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
                                      count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
            {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999}
                                      count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0}
                                      showZero={false}/>}
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
    }

    // let menuEstimates = <MDBSideNavCat
    //     name="工事見積書"
    //     id="user-cat"
    //     icon={iconEstimates}
    //     className={customClassEstimates}
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/estimates'>工事見積書一覧 {this.props.org && this.props.unreadEstimatesWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimatesWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuEstimates = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:4}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="rgba(255,255,255,0.65)"><path
            d="M96-144v-92q0-26 12.5-47.5T143-318q54-32 114.5-49T384-384q66 0 126.5 17T625-318q22 13 34.5 34.5T672-236v92H96Zm72-72h432v-20q0-6.47-3.03-11.76-3.02-5.3-7.97-8.24-47-27-99-41.5T384-312q-54 0-106 14t-99 42q-4.95 2.83-7.98 7.91-3.02 5.09-3.02 12V-216Zm216-216q-60 0-102-42t-42-102h-12q-5.4 0-8.7-3.3-3.3-3.3-3.3-8.7 0-5.4 3.3-8.7 3.3-3.3 8.7-3.3h12q0-40 19.8-72.52Q279.6-705.03 312-724v64q0 5.4 3.3 8.7 3.3 3.3 8.7 3.3 5.4 0 8.7-3.3 3.3-3.3 3.3-8.7v-74.84q11-4.16 22.9-6.66 11.91-2.5 25-2.5 13.1 0 25.1 2.5 12 2.5 23 6.66V-660q0 5.4 3.3 8.7 3.3 3.3 8.7 3.3 5.4 0 8.7-3.3 3.3-3.3 3.3-8.7v-64q32.4 18.87 52.2 51.22Q528-640.43 528-600h12q5.4 0 8.7 3.3 3.3 3.3 3.3 8.7 0 5.4-3.3 8.7-3.3 3.3-8.7 3.3h-12q0 60-42 102t-102 42Zm.21-72Q414-504 435-525.15T456-576H312q0 30 21.21 51t51 21Zm261.94 120-5.07-25.31Q636-411 631.5-414q-4.5-3-8.5-6l-24 9-17-30 19-15.65q0-2.35-.5-5.35-.5-3-.5-6t.5-6q.5-3 .5-5.35L582-495l17-30 24 9q4-3 8.67-6 4.66-3 9.33-5l5.09-25H680l4 25q5 2 10 5t9 6l24-9 17 30-19 15.65q0 2.35.5 5.35.5 3 .5 6t-.5 6q-.5 3-.5 5.35L744-441l-17 30-24-9q-4 3-8.85 5.86-4.86 2.85-10.15 5.14l-4 25h-33.85ZM663-443q11 0 18-7t7-18q0-11-7-18t-18-7q-11 0-18.5 7t-7.5 18q0 11 7.5 18t18.5 7Zm81-133-7-37q-8-2-14-6t-12-9l-35 12-24-42 28-24q-1-4-1.5-7t-.5-7q0-4 .5-7.5t1.5-7.5l-28-24 24-42 35 12q6-5 12-9t14-6l6.88-36H792l8 36q8 2 14 6t12 9l34-12 24 42-27 24q1 4 1 7.5v7.5q0 4-.5 7.5T856-681l28 24-24 42-35-12q-6 5-12 9t-14 6l-6.87 36H744Zm23.96-77q18.04 0 30.54-12.46t12.5-30.5q0-18.04-12.46-30.54t-30.5-12.5q-18.04 0-30.54 12.46t-12.5 30.5q0 18.04 12.46 30.54t30.5 12.5ZM384-216Z"/></svg>
        <span style={{marginLeft: 10}}>工事見積書</span>
      </span>}>

      {this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
          <Menu.Item key={'11'}>
            <NavLink to='/estimates/new'>工事見積書登録</NavLink>
          </Menu.Item>
      }
      {this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
          <Menu.Item key={'12'}>
            <NavLink to='/estimates/new'>工事見積書登録</NavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>

    // let menuBills = <MDBSideNavCat
    //     icon={iconBill}
    //     name="請求書"
    //     className={customClassBill}
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/bills'>請求書一覧 {this.props.org && this.props.unreadBillWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadBillWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuBills = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="M120-80v-800l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v800l-60-60-60 60-60-60-60 60-60-60-60 60-60-60-60 60-60-60-60 60-60-60-60 60Zm120-200h480v-80H240v80Zm0-160h480v-80H240v80Zm0-160h480v-80H240v80Zm-40 404h560v-568H200v568Zm0-568v568-568Z"/></svg>
        <span style={{marginLeft: 10}}>請求書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
               count={(this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      {this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
          <Menu.Item key={'13'}>
            <NavLink to='/bills/new'>請求書登録</NavLink>
          </Menu.Item>
      }
      {this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
          <Menu.Item key={'14'}>
          <NavLink to='/bills/new'>請求書登録</NavLink>
          </Menu.Item>
      }
      <Menu.Item key={'15'}>
        <NavLink to='/bills'>請求書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuDeliveryNotes = <MDBSideNavCat
    //     icon={iconDeliveryNotes}
    //     name="納品書"
    //     className={customClassDeliveryNotes}
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/deliveryNotes'>納品書一覧 {this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadDeliveryNotesWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuDeliveryNotes = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="M320-600q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm0 160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0 160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h440l200 200v440q0 33-23.5 56.5T760-120H200Zm0-80h560v-400H600v-160H200v560Zm0-560v160-160 560-560Z"/></svg>
        <span style={{marginLeft: 10}}>納品書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
               count={(this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) || 0}
               showZero={false}/>
      </span>}>
      {this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
          <Menu.Item key={'16'}>
            <NavLink to='/deliveryNotes/new'>納品書登録</NavLink>
          </Menu.Item>
      }
      {this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
          <Menu.Item key={'17'}>
            <NavLink to='/deliveryNotes/new'>納品書登録</NavLink>
          </Menu.Item>
      }
      <Menu.Item key={'18'}>
        <NavLink to='/deliveryNotes'>納品書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // let menuEstimates2 = <MDBSideNavCat
    //     name="見積書"
    //     id="user-cat"
    //     icon={iconEstimates2}
    //     className={customClassEstimates2}
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
    //         <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuEstimates2 = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
        <span style={{marginLeft: 10}}>見積書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
               count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      {this.state.organization && this.state.organization.reseller &&
          <>
            {this.props.org && this.props.org.buyer &&
                <Menu.Item key={'19'}>
                  <NavLink to='/estimates2/new'>見積書登録</NavLink>
                </Menu.Item>
            }
            <Menu.Item key={'20'}>
              <NavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'}
                                                                 overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'21'}>
            <NavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <>
            <Menu.Item key={'22'}>
              <NavLink to='/estimates2/new'>見積書登録</NavLink>
            </Menu.Item>
            <Menu.Item key={'23'}>
              <NavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
            </Menu.Item>
          </>
      }
    </Menu.SubMenu>;

    // let menuSDS = <MDBSideNavCat
    //     name="SDS一覧"
    //     id="user-cat"
    //     icon={iconSDS}
    //     className={customClassSDS}
    // >
    //   <MDBSideNavLink to='/sdsManagement'>SDS管理一覧 {this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuSDS = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/></svg>
        <span style={{marginLeft: 10}}>SDS一覧</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
               count={(this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      <Menu.Item key={'24'}>
        <NavLink to='/sdsManagement'>SDS管理一覧 <Badge style={{marginLeft: 10}} color={'green'}
                                                               overflowCount={999}
                                                               count={(this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) || 0}
                                                               showZero={false}/></NavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // let menuManual = <MDBSideNavCat
    //     name="取扱説明書一覧"
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/manual/new'>取扱説明書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {/* <MDBSideNavLink to='/manual/new'>取扱説明書登録</MDBSideNavLink> */}
    //         <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuManual = <Menu.SubMenu title={<span>
       <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
           d="M200-800v241-1 400-640 200-200Zm80 400h140q9-23 22-43t30-37H280v80Zm0 160h127q-5-20-6.5-40t.5-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v100q-19-8-39-12.5t-41-6.5v-41H480v-200H200v640h241q16 24 36 44.5T521-80H200Zm460-120q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM864-40 756-148q-21 14-45.5 21t-50.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T812-204L920-96l-56 56Z"/></svg>
        <span style={{marginLeft: 10}}>取扱説明書一覧</span>
      </span>}>
      {this.state.organization && this.state.organization.reseller &&
          <>
            {this.props.org && this.props.org.buyer &&
                <Menu.Item key={'25'}>
                  <NavLink to='/manual/new'>取扱説明書登録</NavLink>
                </Menu.Item>
            }
            <Menu.Item key={'26'}>
              <NavLink to='/manual'>取扱説明書一覧</NavLink>
            </Menu.Item>
          </>
      }
      {this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'27'}>
            <NavLink to='/manual'>取扱説明書一覧</NavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'28'}>
            <NavLink to='/manual'>取扱説明書一覧</NavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuCatalog = <MDBSideNavCat
    //     name="カタログ一覧"
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/catalog/new'>カタログ登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {/* <MDBSideNavLink to='/catalog/new'>カタログ登録</MDBSideNavLink> */}
    //         <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuCatalog = <Menu.SubMenu title={<span>
      <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
          d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640H447l-80-80H160v480l96-320h684L837-217q-8 26-29.5 41.5T760-160H160Zm84-80h516l72-240H316l-72 240Zm0 0 72-240-72 240Zm-84-400v-80 80Z"/></svg>
        <span style={{marginLeft: 10}}>カタログ一覧</span>
      </span>}>
      {this.state.organization && this.state.organization.reseller &&
          <>
            {this.props.org && this.props.org.buyer &&
                <Menu.Item key={'29'}>
                  <NavLink to='/catalog/new'>カタログ登録</NavLink>
                </Menu.Item>
            }
            <Menu.Item key={'30'}>
              <NavLink to='/catalog'>カタログ一覧</NavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'31'}>
            <NavLink to='/catalog'>カタログ一覧</NavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'32'}>
            <NavLink to='/catalog'>カタログ一覧</NavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuReport = <MDBSideNavCat
    //     name="報告書"
    //     id="user-cat"
    //     icon={iconReport}
    //     className={customClassReport}
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/report/new'>報告書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {<MDBSideNavLink to='/report/new'>報告書登録</MDBSideNavLink>}
    //         <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuReport = <Menu.SubMenu title={<span>
       <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
           d="M280-320q17 0 28.5-11.5T320-360q0-17-11.5-28.5T280-400q-17 0-28.5 11.5T240-360q0 17 11.5 28.5T280-320Zm-40-120h80v-200h-80v200Zm160 80h320v-80H400v80Zm0-160h320v-80H400v80ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>
        <span style={{marginLeft: 10}}>報告書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999}
               count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      {this.state.organization && this.state.organization.reseller &&
          <>
            {this.props.org && this.props.org.buyer &&
                <Menu.Item key={'33'}>
                  <NavLink to='/report/new'>報告書登録</NavLink>
                </Menu.Item>
            }
            <Menu.Item key={'34'}>
              <NavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'}
                                                             overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'35'}>
            <NavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'36'}>
            <NavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></NavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuDeliveryAddress = <MDBSideNavCat
    //     name="納品場所管理"
    //     id="user-cat"
    // >
    //   <MDBSideNavLink to='/deliveryAddress'>納品場所管理(アドレス帳)</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuDeliveryAddress = <Menu.SubMenu title={<span>
        <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="16px" fill="rgba(255,255,255,0.65)"><path
            d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>
        <span style={{marginLeft: 10}}>納品場所管理</span>
      </span>}>
      <Menu.Item key={37}>
        <NavLink to='/deliveryAddress'>納品場所管理(アドレス帳)</NavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // console.log('this.props.user',this.props.user)
    if (this.props.user.admin) {
      // menuStatistic = <></>;
      menuCompany = <></>;
      menuUserInfo = <></>;
      // menuItem = <></>;
      menuFavoriteCart = <></>;
      menuChumon = <></>;
      menuEstimates = <></>;
      menuBills = <></>;
      menuDeliveryNotes = <></>;
      menuEstimates2 = <></>;
      // menuSDS = <></>;
      menuManual = <></>;
      menuCatalog = <></>;
      menuReport = <></>;
      // menuStatistic = <></>;
      menuOtasukeCart = <></>;
      menuCart = <></>;
      menuDeliveryAddress = <></>;
      menuP4Bill = <></>;

      menuSDS = <MDBSideNavCat
          name="SDS管理一覧"
          id="user-cat"
          icon={iconSDS}
          className={customClassSDS}
      >
        <NavLink to='/sdsManagement'>SDS管理一覧 {this.props.org && this.props.sdsBuyerWaitingCountWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsBuyerWaitingCountWithOrg[this.props.org.id]}</MDBBadge>}</NavLink>
      </MDBSideNavCat>;
    }

    let sellerOrganizations = this.state.sellerOrganizations;
    if (this.state.searchOrg) {
      sellerOrganizations = this.state.filteredOrg;
    }

    let buyerOrganizations = this.state.buyerOrganizations;
    if (this.state.searchOrg1) {
      buyerOrganizations = this.state.filteredOrg1;
    }

    let buyersOrganizations = this.state.organization && this.state.organization.buyers;
    if (this.state.searchOrgBuyer || this.state.searchOrgBuyerSeller) {
      buyersOrganizations = this.state.filteredOrgBuyer;
    }

    let sellersOrganizations = this.state.organization && this.state.organization.sellers;
    if (this.state.searchOrgSeller || this.state.searchOrgBuyerSeller) {
      sellersOrganizations = this.state.filteredOrgSeller;
    }

    let customersOrganizations = this.state.organization && this.state.organization.customers;
    if (this.state.searchOrgCustomer) {
      customersOrganizations = this.state.filteredOrgCustomer;
    }

    return (
        <>
          <Sider trigger={null} theme={'dark'} style={siderStyle} width={260} collapsible collapsed={this.props.collapsed}>
            <div className="logo-wrapper">
              <img src="/logo.png" className="img-fluid" alt="HOME" />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                // selectedKeys={[this.state.current]}
                // onClick={(e) => console.log('e', e)}
            >
              { menuStatistic }
              { menuCompany }
              { menuUserInfo }
              { menuItem }
              { menuFavoriteCart }
              { menuChumon }
              { menuEstimates }
              { menuBills }
              { menuDeliveryNotes }
              { menuEstimates2 }
              { menuSDS }
              { menuManual }
              { menuCatalog }
              { menuReport }
              { menuOtasukeCart }
              { menuCart }
              { menuDeliveryAddress }
              <br/>
              { menuP4Bill }
            </Menu>
          </Sider>
          {/*<MDBSideNav*/}
          {/*  triggerOpening={this.state.toggleStateA}*/}
          {/*  breakWidth={this.props.breakWidth}*/}
          {/*  fixed={true}*/}
          {/*  mask="strong"*/}
          {/*>*/}
          {/*  <MDBSideNavNav>*/}
          {/*      <div className="logo-wrapper">*/}
          {/*      <MDBSideNavLink to='/'>*/}
          {/*        <img src="/logo.png" className="img-fluid" alt="HOME" />*/}
          {/*        </MDBSideNavLink>*/}
          {/*      </div>*/}

          {/*    {menuStatistic}*/}

          {/*    {menuCompany}*/}

          {/*    {menuUserInfo}*/}

          {/*    {menuKoutei}*/}

          {/*    {menuItem}*/}

          {/*    {menuFavoriteCart}*/}

          {/*    {menuChumon}*/}

          {/*    {menuEstimates}*/}

          {/*    {menuBills}*/}

          {/*    {menuDeliveryNotes}*/}

          {/*    {menuEstimates2}*/}

          {/*    {menuSDS}*/}

          {/*    {menuManual}*/}

          {/*    {menuCatalog}*/}

          {/*    {menuReport}*/}

          {/*    /!*{menuStatistic}*!/*/}

          {/*    {menuOtasukeCart}*/}

          {/*    {menuCart}*/}

          {/*    {menuDeliveryAddress}*/}

          {/*    <br />*/}
          {/*    {menuP4Bill}*/}

          {/*  </MDBSideNavNav>*/}
          {/*</MDBSideNav>*/}
          {/*<MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>*/}
          {/*  <MDBNavbarNav left>*/}
          {/*    <MDBNavItem>*/}
          {/*      <div*/}
          {/*        onClick={this.handleToggleClickA}*/}
          {/*        key="sideNavToggleA"*/}
          {/*        style={{*/}
          {/*          lineHeight: "32px",*/}
          {/*          marginRight: "1em",*/}
          {/*          verticalAlign: "middle"*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <MDBIcon icon="bars" color="white" size="2x" />*/}
          {/*      </div>*/}
          {/*    </MDBNavItem>*/}
          {/*    <MDBNavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}>*/}
          {/*    {this.state.organization && this.state.organization.buyer && !this.props.location.pathname.startsWith('/measurement') &&*/}
          {/*        <>*/}
          {/*          {this.state.seller_company ? this.state.seller_company.name : ''} {this.state.seller ? this.state.seller.name : ''}*/}
          {/*        </>*/}
          {/*    }*/}
          {/*      {this.state.organization && this.state.organization.buyer && this.props.location.pathname.startsWith('/measurement') &&*/}
          {/*          <>*/}
          {/*            [測定データモード]*/}
          {/*          </>*/}
          {/*      }*/}
          {/*    {this.state.organization && this.state.organization.administrator &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (this.props.org && this.props.org.display_name) ?*/}
          {/*              <>*/}
          {/*                {this.props.org.display_name}*/}
          {/*              </>*/}
          {/*              :*/}
          {/*              <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgCustomer} onChange={this.handleChangeCustomer} onKeyDown={(event) => this.handleKeyDown(event, customersOrganizations || [], [])}/>*/}
          {/*          {this.state.organization && customersOrganizations &&*/}
          {/*              customersOrganizations.map((customers, i) => {*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={customers.organization} active={customers.organization === this.state.activeOrg.organization} data-org={customers.organization} data-name={customers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[customers.organization]}</MDBBadge>} {customers.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*              }*/}
          {/*            )*/}
          {/*          }*/}

          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    {this.state.organization && this.state.organization.reseller &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (this.props.org && this.props.org.display_name && !this.props.location.pathname.startsWith('/measurement')) ?*/}
          {/*              <>*/}
          {/*                {this.props.org.maker ? '[仕入モード]　' : ''}*/}
          {/*                {this.props.org.buyer ? '[販売モード]　' : ''}*/}
          {/*                {this.props.org.display_name}*/}
          {/*              </>*/}
          {/*              :*/}
          {/*                this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgBuyerSeller} onChange={this.handleChangeBuyerSeller} onKeyDown={(event) => this.handleKeyDown(event, buyersOrganizations || [], sellersOrganizations || [])}/>*/}
          {/*          {this.state.organization && buyersOrganizations &&*/}
          {/*              buyersOrganizations.map((buyers, i) => {*/}
          {/*              return <MDBDropdownItem key={i} href="#!" id={buyers.organization} active={buyers.organization === this.state.activeOrg.organization} data-org={buyers.organization} data-name={buyers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                {this.state.organizationUnread[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[buyers.organization]}</MDBBadge>} [売先] {buyers.name}*/}
          {/*                {this.props.notShippedOrderWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*              </MDBDropdownItem>*/}
          {/*            })*/}
          {/*          }*/}
          {/*          /!*<li>*!/*/}
          {/*          /!*  <hr className="dropdown-divider"/>*!/*/}
          {/*          /!*</li>*!/*/}
          {/*          {this.state.organization && sellersOrganizations &&*/}
          {/*              sellersOrganizations.map((seller, i) => {*/}
          {/*                // console.log('seller', seller)*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={seller.organization} active={seller.organization === this.state.activeOrg.organization} data-org={seller.organization} data-name={seller.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[seller.organization]}</MDBBadge>} [仕入先] {seller.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*            })*/}
          {/*          }*/}

          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    {this.state.organization && this.state.organization.maker &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (!this.props.location.pathname.startsWith('/measurement') && this.props.org && this.props.org.display_name) ?*/}
          {/*              <>{this.props.org.display_name}</>*/}
          {/*              :*/}
          {/*                this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgBuyer} onChange={this.handleChangeBuyers} onKeyDown={(event) => this.handleKeyDown(event, buyersOrganizations || [], [])}/>*/}
          {/*          {this.state.organization && buyersOrganizations &&*/}
          {/*              buyersOrganizations.map((buyers, i) => {*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={buyers.organization} active={buyers.organization === this.state.activeOrg.organization} data-org={buyers.organization} data-name={buyers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[buyers.organization]}</MDBBadge>} {buyers.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*              }*/}
          {/*            )*/}
          {/*          }*/}
          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*      {this.props.user.admin &&*/}
          {/*        <div style={{display: 'flex'}}>*/}
          {/*          <MDBDropdown>*/}
          {/*            <MDBDropdownToggle nav caret ref={input => this.mdbToggle1 = input}>*/}
          {/*              <div className={'d-none d-md-inline'}>*/}
          {/*                { (!this.props.location.pathname.startsWith('/measurement') && this.props.orgSeller && this.props.orgSeller.display_name) ?*/}
          {/*                    <>{this.props.orgSeller.display_name}</>*/}
          {/*                    :*/}
          {/*                    this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>販売モード側事業所選択</>*/}
          {/*                }*/}
          {/*              </div>*/}
          {/*            </MDBDropdownToggle>*/}
          {/*            <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*              <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrg} onChange={this.handleChange} onKeyDown={(event) => this.handleKeyDown1(event, sellerOrganizations || [])}/>*/}
          {/*              {*/}
          {/*                sellerOrganizations.map((seller, i) => {*/}
          {/*                  return <MDBDropdownItem key={i} href="#!" id={seller.id} active={seller.id === this.state.activeOrg1.id} data-org={seller.organization} data-name={seller.company_name + ' ' + seller.name} onClick={(v) => this.selectSellerOrganization(seller, false)}>*/}
          {/*                    [販売側] {seller.company_name} {seller.name}*/}
          {/*                    {this.props.sdsNormalCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsWarningCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsAlertCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsWaitingCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                  </MDBDropdownItem>*/}
          {/*                })*/}
          {/*              }*/}
          {/*            </MDBDropdownMenu>*/}
          {/*          </MDBDropdown>*/}
          {/*          {*/}
          {/*              !this.props.location.pathname.startsWith('/measurement') && <MDBDropdown>*/}
          {/*                <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*                  <div className={'d-none d-md-inline'}>*/}
          {/*                    { (this.props.org && this.props.org.display_name) ?*/}
          {/*                        <>{this.props.org.display_name}</>*/}
          {/*                        :*/}
          {/*                        <>仕入モード側事業所選択</>*/}
          {/*                    }*/}
          {/*                  </div>*/}
          {/*                </MDBDropdownToggle>*/}
          {/*                <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*                  <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrg1} onChange={this.handleChange1} onKeyDown={(event) => this.handleKeyDown(event, buyerOrganizations || [], [])}/>*/}
          {/*                  {*/}
          {/*                    buyerOrganizations.map((buyer, i) => {*/}
          {/*                      return <MDBDropdownItem key={i} href="#!" id={buyer.organization} active={buyer.organization === this.state.activeOrg.organization} data-org={buyer.organization} data-name={buyer.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                        [仕入側] {buyer.name}*/}
          {/*                        {this.props.sdsNormalCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsWarningCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsAlertCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsWaitingCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                      </MDBDropdownItem>*/}
          {/*                    })*/}
          {/*                  }*/}
          {/*                </MDBDropdownMenu>*/}
          {/*              </MDBDropdown>*/}
          {/*          }*/}

          {/*        </div>*/}
          {/*      }*/}
          {/*    </MDBNavItem>*/}
          {/*  </MDBNavbarNav>*/}
          {/*  <MDBNavbarNav right style={specialCaseNavbarStyles}>*/}
          {/*    <MDBNavItem style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*      <MDBSideNavLink to='/support'>*/}
          {/*        <MDBIcon icon="question-circle" className="d-inline-inline" />{" "}*/}
          {/*        <div className="d-none d-md-inline">Q&A（よくある質問）</div>*/}
          {/*      </MDBSideNavLink>*/}
          {/*    </MDBNavItem>*/}
          {/*    <MDBNavItem>*/}
          {/*      <MDBDropdown style={{color: '#000000'}}>*/}
          {/*        <MDBDropdownToggle nav caret>*/}
          {/*          {this.state.unread > 0 &&*/}
          {/*              <div className="d-none d-md-inline" style={{marginRight: 10}}>*/}
          {/*                未読 <MDBBadge className="ml-2 custom-badge">{this.state.unread}</MDBBadge>*/}
          {/*              </div>*/}
          {/*          }*/}
          {/*          {this.state.unreadNotShipped > 0 &&*/}
          {/*              <div className={"d-none d-md-inline"} style={{marginRight: 10}}>*/}
          {/*                未完 <MDBBadge className="ml-2 custom-badge"*/}
          {/*                             color="danger">{this.state.unreadNotShipped}</MDBBadge>*/}
          {/*              </div>*/}
          {/*          }*/}
          {/*          <div className={"d-none d-md-inline"} style={{marginRight: 10}}>*/}
          {/*            SDS*/}
          {/*            {*/}
          {/*              this.props.user.admin ? <>*/}
          {/*                {*/}
          {/*                    this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*              </> : <>*/}
          {/*                {*/}
          {/*                    this.props.sdsNormalCount > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWarningCount > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsAlertCount > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWaitingCount > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCount}</MDBBadge>*/}
          {/*                }*/}
          {/*              </>*/}
          {/*            }*/}

          {/*          </div>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            <MDBIcon icon="user" className="d-inline-inline" />{" "}*/}
          {/*             {this.props.user.last_name} {this.props.user.first_name}*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left>*/}
          {/*          /!**/}
          {/*          <MDBDropdownItem href="#!">ユーザー情報</MDBDropdownItem>*/}
          {/*          *!/*/}

          {/*          <MDBDropdownItem header style={{marginTop: 20,marginBottom: 20, textAlign: 'center', weight:'14'}}>{this.props.user.last_name} {this.props.user.first_name}</MDBDropdownItem>*/}
          {/*          /!*<MDBDropdownItem header style={{marginTop: 20,marginBottom: 20, textAlign: 'center', weight:'14'}}>{admin.auth().getUser(this.props.user.uid)</MDBDropdownItem>*!/*/}
          {/*          <MDBDropdownItem header style={{textAlign: 'center'}}>{this.state.company ? this.state.company.name : ''}</MDBDropdownItem>*/}
          {/*          <MDBDropdownItem header style={{textAlign: 'center'}}>{this.state.organization ? this.state.organization.name : ''}</MDBDropdownItem>*/}
          {/*          <MDBDropdownItem href={'#!'} style={{marginTop: 20, marginBottom: 20, fontWeight: 'bold', color: 'black', textAlign: 'center'}}>*/}
          {/*            <MDBSideNavLink to='/emailsettings'>*/}
          {/*              <MDBIcon icon="envelope" style={{color: '#2bbbad', fontSize: 16}} size={'1x'} /> 通知メール受信設定*/}
          {/*            </MDBSideNavLink>*/}
          {/*          </MDBDropdownItem>*/}
          {/*          <MDBDropdownItem href="#!"*/}
          {/*                onClick={() => {*/}
          {/*                  this.props.logout();*/}
          {/*                  // window.location.href="/";*/}
          {/*                }}*/}
          {/*                           style={{border:1, borderStyle:'solid', borderColor: '#D9D9D9',marginTop: 20,marginBottom: 30, textAlign: 'center'}}*/}
          {/*              >ログアウト*/}
          {/*          </MDBDropdownItem>*/}
          {/*          /!*<MDBDropdownItem divider/>*!/*/}
          {/*          /!*<MDBDropdownItem style={{textAlign: 'center'}}>*!/*/}
          {/*          /!*  <MDBIcon icon="question-circle" /> ヘルプ*!/*/}
          {/*          /!*</MDBDropdownItem>*!/*/}
          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*    </MDBNavItem>*/}
          {/*  </MDBNavbarNav>*/}
          {/*</MDBNavbar>*/}
        </>
    );
  }
}

export default withRouter(DoubleNavigationPage);
