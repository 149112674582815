import React from "react";
import { withRouter, NavLink } from 'react-router-dom'
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBSideNavLink, MDBNavLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBSideNavItem, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBContainer, MDBBadge } from "mdbreact";
import "./P4Header.css";
import { Button, Layout, Menu, theme, Badge } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'sticky',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarGutter: 'stable',
};

class DoubleNavigationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
      organization: null,
      company: null,
      seller: null,
      email: null,
      seller_company: null,
      organizationUnread: {},
      unread: 0,
      unreadNotShipped: 0,
      sellerOrganizations: [],
      buyerOrganizations: [],
      sellerOrg: null,
      buyerOrg: null,
      searchOrg: '',
      filteredOrg: [],

      searchOrg1: '',
      filteredOrg1: [],

      searchOrgSeller: '',
      filteredOrgSeller: [],

      searchOrgBuyer: '',
      filteredOrgBuyer: [],

      searchOrgCustomer: '',
      filteredOrgCustomer: [],

      searchOrgBuyerSeller: '',

      activeOrg: {
        organization: '',
        name: ''
      },

      activeOrg1: {
        id: '',
        company_name: '',
        name: ''
      },
      current: 37
    };
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA
    });
  };

  organizationCheck = () => {
    if(this.props.user && this.props.user.organization){

      let organizationRef = this.props.db.collection('organizations').doc(this.props.user.organization);
      let getDoc = organizationRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such organization document!')
          } else {
            if ( doc.data().active ) {
              let org = doc.data();
              org.id = doc.id;

              console.log('org list: ', this.props.user.organization, org)
              this.setState({
                organization: org,
              });
             //this.props.setupOrgAndCompany(org);

              this.companyCheck();
              this.sellerCheck();

            }
          }
        })
        .catch(err => {
          console.log('Error getting organization document', this.props.user.organization, err)
        })
    } else if (this.props.user && this.props.user.admin) {
      // console.log('organization check');

      this.adminUserList()
          .then((snapshot) => {
            // console.log('organizations',snapshot)
            // snapshot.forEach((doc) => {
            //   console.log(doc.data())
            // })
            // console.log('organization length:',snapshot.length)
            if (snapshot.length > 0) {
              let organizations = [];
              snapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                organizations.push(data)
              });

              // console.log(organizations)

              this.setState({
                sellerOrganizations: organizations,
                buyerOrganizations: []
              })
            } else {
              console.log('No such organization document!')
            }
          })
    }

  }

  adminUserList = async () => {
    const seller = this.props.db.collection('organizations').where('reseller', '==', true).get();
    const maker = this.props.db.collection('organizations').where('maker', '==', true).get();

    const [
      sellerSnapshot,
      makerSnapshot
    ] = await Promise.all([seller, maker]);

    const sellerArray = sellerSnapshot.docs || [];
    const makerArray = makerSnapshot.docs || [];

    //Note that we don't need to de-duplicate in this case
    return sellerArray.concat(makerArray);
  }

  companyCheck = () => {
    if(this.state.organization){

      let companyRef = this.props.db.collection('company').doc(this.state.organization.company);
      let getDoc = companyRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such company document!')
          } else {
            if ( doc.data().active ) {
              let company = doc.data();
              company.id = doc.id;
              this.setState({
                company: company,
              });
              let org = this.state.organization;
              org.display_name = company.name + ' ' + org.name;
              console.log('org', org.display_name)

              this.props.setupOrgAndCompany(org);
            }
          }
        })
        .catch(err => {
          console.log('Error getting company document', err)
        })
    }

  }



  sellerCheck = () => {
    if(this.state.organization && this.state.organization.seller){

      let sellerRef = this.props.db.collection('organizations').doc(this.state.organization.seller);
      let getDoc = sellerRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such seller document!')
          } else {
            if ( doc.data().active ) {
              let seller = doc.data();
              seller.id = doc.id;
              this.setState({
                seller: seller,
              });
              // console.log('seller', seller)
              this.seller_companyCheck();
            }
          }
        })
        .catch(err => {
          console.log('Error getting seller document', err)
        })
    }

  }

  seller_companyCheck = () => {
    if(this.state.organization){

      let companyRef = this.props.db.collection('company').doc(this.state.seller.company);
      let getDoc = companyRef.get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such seller_company document!')
          } else {
            if ( doc.data().active ) {
              this.setState({
                seller_company: doc.data(),
              });
              this.props.orgSelectData(this.state.seller.id, this.state.seller_company.name + ' ' + this.state.seller.name);
            }
          }
        })
        .catch(err => {
          console.log('Error getting seller_company document', err)
        })
    }

  }


  componentDidMount() {
    this.organizationCheck();
  }

  sumObjectsByKey = (...objs) => {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k))
          a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  }

  // componentWillReceiveProps()

  componentWillReceiveProps(nextProps, nextContext) {
    // this.props.unreadOrderWithOrg[customers.organization]
    // console.log('p4header', nextProps.location.pathname)

    // console.log(nextProps.orgSeller && nextProps.orgSeller.id, this.props.orgSeller && this.props.orgSeller.id);
    if ((nextProps.orgSeller && nextProps.orgSeller.id) !== this.state.sellerOrg) {
      // console.log('change org');
      this.setState({
        sellerOrg: nextProps.orgSeller.id
      }, () => {
        this.selectSellerOrganization(nextProps.orgSeller, true);
      })
    }

    // if (nextProps.width !== this.props.width && !this.state.mainToggle) {
    //   console.log('componentWillReceiveProps', this.props.width, this.props.breakWidth);
    //   if (this.props.width < this.props.breakWidth) {
    //     this.setState({
    //       mainToggle: true
    //     }, () => {
    //       this.props.mainToggle(this.state.mainToggle);
    //     })
    //   }
    // }

    this.setState({
      organizationUnread: this.sumObjectsByKey(
          nextProps.unreadOrderWithOrg,
          nextProps.unreadBillWithOrg,
          nextProps.unreadDeliveryNotesWithOrg,
          nextProps.unreadEstimates2WithOrg,
          nextProps.unreadEstimatesWithOrg,
          nextProps.unreadReportWithOrg,
          nextProps.unreadOCHWithOrg,
          // nextProps.sdsWaitingCountWithOrg
      ),
      unread: (
          nextProps.unreadOrder+
          nextProps.unreadBill+
          nextProps.unreadDeliveryNotes+
          nextProps.unreadEstimates2+
          nextProps.unreadEstimates+
          nextProps.unreadReport+
          nextProps.unreadOCH
          // nextProps.sdsWaitingCount
      ),
      unreadNotShipped: nextProps.notShippedOrder
    }, () => {
      // console.log(this.state.organizationUnread)
    })

  }

  selectSellerOrganization = (seller, initial) => {
    // console.log('buyers',seller.buyers);
    this.props.orgSelectSeller(seller.id, (seller.company_name || '') + ' ' + seller.name, initial);
    this.setState({
      buyerOrganizations: seller.buyers || []
    })
  }

  adminBadgeCount = (sellerId, buyerId, type) => {
    // {this.props.sdsNormalCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsWarningCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsAlertCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[customers.organization]}</MDBBadge>}
    // {this.props.sdsWaitingCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[customers.organization]}</MDBBadge>}

    switch (type) {
      case 'waiting':
        console.log(this.props.sdsWaitingCountWithOrg[buyerId])
        return 0
      case 'normal': return 0;
      case 'warning': return 0;
      case 'alert': return 0;
      default:
        return 0
    }
  }

  handleChange = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.sellerOrganizations.filter(o => {
        let name = o.name && o.name.toLowerCase();
        let company_name = o.company_name && o.company_name.toLowerCase();

        return (name && name.includes(value)) || (company_name && company_name.includes(value))
      });
    }

    this.setState({searchOrg: event.target.value, filteredOrg: result});
  }

  handleChange1 = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.buyerOrganizations.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    console.log('result',result)

    this.setState({searchOrg1: event.target.value, filteredOrg1: result});
  }

  handleChangeCustomer = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.customers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgCustomer: event.target.value, filteredOrgCustomer: result});
  }

  handleChangeBuyers = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.buyers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgBuyer: event.target.value, filteredOrgBuyer: result});
  }

  handleChangeSeller = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let result = [];
    if (value) {
      value = this.toASCII(value);
      result = this.state.organization.sellers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgSeller: event.target.value, filteredOrgSeller: result});
  }

  handleChangeBuyerSeller = (event) => {
    let value = event.target.value && event.target.value.toLowerCase();
    let resultBuyer = [];
    let resultSeller = [];
    if (value) {
      value = this.toASCII(value);
      resultSeller = this.state.organization && this.state.organization.sellers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })

      resultBuyer = this.state.organization && this.state.organization.buyers.filter(o => {
        let name = o.name && o.name.toLowerCase();

        return (name && name.includes(value))
      })
    }

    this.setState({searchOrgBuyerSeller: event.target.value, filteredOrgSeller: resultSeller, filteredOrgBuyer: resultBuyer});
  }

  handleKeyDown1 = (event, organizations1) => {
    // console.log(event.key, organizations1);

    if (event.key === 'Tab' || (event.shiftKey && event.key === 'Tab') || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();

      let findIndex1 = organizations1.findIndex(org => {
        return org.id === this.state.activeOrg1.id
      });

      // console.log(findIndex1)

      if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
        if (findIndex1 > 0) {
          this.setState({
            activeOrg1: organizations1[findIndex1-1] || {
              id: '',
              name: '',
            }
          });
        }
      } else {
        if (findIndex1 === -1) {
          this.setState({
            activeOrg1: organizations1[0] || {
              id: '',
              name: ''
            }
          });
        } else {
          if (findIndex1+1 !== organizations1.length) {
            this.setState({
              activeOrg1: organizations1[findIndex1+1] || {
                id: '',
                name: ''
              }
            });
          }

        }
      }
    }

    let access = document.getElementById(this.state.activeOrg1.id);
    if (access) {
      access.scrollIntoView({block: "center"});
    }

    if (event.key === 'Enter') {
      this.mdbToggle1.context.toggle();

      this.selectSellerOrganization(this.state.activeOrg1, false);
    }
  }

  handleKeyDown = (event, organizations1, organizations2) => {
    // console.log(event.key, organizations1, organizations2);

    if (event.key === 'Tab' || (event.shiftKey && event.key === 'Tab') || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();

      let findIndex1 = organizations1.findIndex(org => {
        return org.organization === this.state.activeOrg.organization
      });

      let findIndex2 = organizations2.findIndex(org => {
        return org.organization === this.state.activeOrg.organization
      });

      // console.log(findIndex1, findIndex2)

      if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
        if (findIndex1 > 0) {
          this.setState({
            activeOrg: organizations1[findIndex1-1] || {
              organization: '',
              name: ''
            }
          });
        }

        if (findIndex2 > 0) {
          this.setState({
            activeOrg: organizations2[findIndex2-1] || {
              organization: '',
              name: ''
            }
          });
        } else if (findIndex2 === 0) {
          this.setState({
            activeOrg: organizations1[organizations1.length-1] || {
              organization: '',
              name: ''
            }
          });
        }
      } else {
        if (findIndex1 === -1 && findIndex2 === -1) {
          this.setState({
            activeOrg: organizations1[0] || {
              organization: '',
              name: ''
            }
          });
        } else {
          if (findIndex1 >= 0) {
            if (findIndex1+1 === organizations1.length) {
              if (organizations2.length > 0) {
                this.setState({
                  activeOrg: organizations2[0] || {
                    organization: '',
                    name: ''
                  }
                });
              }
            } else {
              this.setState({
                activeOrg: organizations1[findIndex1+1] || {
                  organization: '',
                  name: ''
                }
              });
            }
          }

          if (findIndex2 >= 0) {
            if (findIndex2+1 !== organizations2.length) {
              this.setState({
                activeOrg: organizations2[findIndex2+1] || {
                  organization: '',
                  name: ''
                }
              });
            }
          }

        }
      }
    }

    let access = document.getElementById(this.state.activeOrg.organization);
    if (access) {
      access.scrollIntoView({block: "center"});
    }

    if (event.key === 'Enter') {
      // console.log(this.mdbToggle)
      this.mdbToggle.context.toggle();
      this.props.orgSelect({
        preventDefault: () => {},
        currentTarget: {
          dataset: {
            org: this.state.activeOrg.organization,
            name: this.state.activeOrg.name
          }
        }
      })
    }
  }

  toASCII = (chars) => {
    let ascii = '';
    for(let i=0, l=chars.length; i<l; i++) {
      let c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xFF00 && c <= 0xFFEF) {
        c = 0xFF & (c + 0x20);
      }

      ascii += String.fromCharCode(c);
    }

    return ascii;
  }

  setCollapse = (collapse) => {
    this.setState({collapse: collapse})
  }

  render() {
    //console.log(nextProps.user);
    let navStyle = {
      paddingLeft:
        this.props.width > this.props.breakWidth ? "210px" : "16px"
    };
    if (this.state.organization && this.state.organization.maker){
      navStyle = {
        paddingLeft:
          this.props.width > this.props.breakWidth ? "210px" : "16px",
        backgroundColor:
          "#b71c1c"
      };
    }

    if (this.props.location.pathname.startsWith('/measurement')) {
      navStyle = {
        paddingLeft:
            this.props.width > this.props.breakWidth ? "210px" : "16px",
        backgroundColor:
            "#5E5E5E"
      };
    }

    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row"
    };

    let itemCatName="商品購入"
    if (this.state.organization && this.state.organization.maker ){
      itemCatName="商品一覧"
    }

    let menuKoutei = <></>
      // <MDBSideNavCat
      //   name="工程エリア管理"
      //   id="user-cat"
      // >
      //   <MDBSideNavItem>サブメニュー</MDBSideNavItem>
      //   <MDBSideNavItem>サブメニュー</MDBSideNavItem>
      // </MDBSideNavCat>
    if(this.state.organization && this.state.organization.reseller){
      menuKoutei = <></>
    }
    if(this.state.organization && this.state.organization.maker){
      menuKoutei = <></>
    }

    let iconReport = '';
    let customClassReport = '';
    if (this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) {
      iconReport = 'bell';
      customClassReport = 'menuWithNotif'
    }

    let iconEstimates2 = '';
    let customClassEstimates2 = '';
    if (this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) {
      iconEstimates2 = 'bell';
      customClassEstimates2 = 'menuWithNotif'
    }

    let iconEstimates = '';
    let customClassEstimates = '';
    if (this.props.org && this.props.unreadEstimatesWithOrg[this.props.org.id]) {
      iconEstimates = 'bell';
      customClassEstimates = 'menuWithNotif'
    }

    let iconBill = '';
    let customClassBill = '';
    if (this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) {
      iconBill = 'bell';
      customClassBill = 'menuWithNotif'
    }

    let iconDeliveryNotes = '';
    let customClassDeliveryNotes = '';
    if (this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) {
      iconDeliveryNotes = 'bell';
      customClassDeliveryNotes = 'menuWithNotif'
    }

    let iconSDS = '';
    let customClassSDS = '';
    if (this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) {
      iconSDS = 'bell';
      customClassSDS = 'menuWithNotif'
    }

    let icon = '';
    let customClass = '';
    if (this.props.org && this.props.unreadOrderWithOrg[this.props.org.id]) {
      // console.log('org',this.props.org);
      icon = this.props.unreadOrder > 0 ? "bell" : "";
      customClass = this.props.unreadOrder > 0 ? "menuWithNotif" : "";
    }

    let iconOCH = 'shopping-cart';
    let customClassOCH = '';
    if (this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) {
      iconOCH = this.props.unreadOCH > 0 ? "bell": "shopping-cart";
      customClassOCH = this.props.unreadOCH > 0 ? "menuWithNotif" : "";
    }

    // let menuOtasukeCart = <MDBSideNavCat
    //     name="お助けカート"
    //     id="user-cat"
    //     icon="shopping-cart"
    // >
    //   <MDBSideNavLink to='/otasuke/carts'>お助けカート一覧</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/1'><MDBIcon icon="shopping-cart" />お助けカート1</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/2'><MDBIcon icon="shopping-cart" />お助けカート2</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/3'><MDBIcon icon="shopping-cart" />お助けカート3</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/4'><MDBIcon icon="shopping-cart" />お助けカート4</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/cart/5'><MDBIcon icon="shopping-cart" />お助けカート5</MDBSideNavLink>
    //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート送信履歴</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuOtasukeCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>お助けカート</span>
      </span>}>
      <Menu.Item key={'116'}>
        <MDBSideNavLink to='/otasuke/carts'>
          <span>お助けカート一覧</span>
        </MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'115'}>
        <MDBSideNavLink to='/otasuke/cart/1'><MDBIcon icon="shopping-cart" />お助けカート1</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'114'}>
        <MDBSideNavLink to='/otasuke/cart/2'><MDBIcon icon="shopping-cart" />お助けカート2</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'113'}>
        <MDBSideNavLink to='/otasuke/cart/3'><MDBIcon icon="shopping-cart" />お助けカート3</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'112'}>
        <MDBSideNavLink to='/otasuke/cart/4'><MDBIcon icon="shopping-cart" />お助けカート4</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'111'}>
        <MDBSideNavLink to='/otasuke/cart/5'><MDBIcon icon="shopping-cart" />お助けカート5</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'110'}>
        <MDBSideNavLink to='/otasuke/history/carts'>お助けカート送信履歴</MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if (this.state.organization && this.state.organization.buyer){
      // menuOtasukeCart = <MDBSideNavCat
      //     name="お助けカート"
      //     id="user-cat"
      //     // icon="shopping-cart"
      //     className={customClassOCH}
      //     icon={iconOCH}
      // >
      //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 {this.props.org && this.props.unreadOCHWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOCHWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
      // </MDBSideNavCat>

      menuOtasukeCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>お助けカート</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
        <Menu.Item key={'109'}>
          <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadOCHWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
        </Menu.Item>
      </Menu.SubMenu>
    }

    if (this.state.organization && this.state.organization.reseller) {
      if (this.props.org && this.props.org.maker) {
        // menuOtasukeCart = <MDBSideNavCat
        //     name="お助けカート"
        //     id="user-cat"
        //     className={customClassOCH}
        //     icon={iconOCH}
        // >
        //   <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 {this.props.org && this.props.unreadOCHWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOCHWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
        // </MDBSideNavCat>

        menuOtasukeCart = <Menu.SubMenu title={<span>
          <UserOutlined />
          <span>お助けカート</span>
        </span>}>
          <Menu.Item key={'108'}>
            <MDBSideNavLink to='/otasuke/history/carts'>お助けカート一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOCHWithOrg[this.props.org.id] || 0} showZero={false}/></MDBSideNavLink>
          </Menu.Item>
        </Menu.SubMenu>
      }
    }

    // let menuCart = <MDBSideNavCat
    //   name="カート"
    //   id="user-cat"
    //   icon="shopping-cart"
    // >
    //   <MDBSideNavLink to='/cart/1'><MDBIcon icon="shopping-cart" />カート1</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/2'><MDBIcon icon="shopping-cart" />カート2</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/3'><MDBIcon icon="shopping-cart" />カート3</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/4'><MDBIcon icon="shopping-cart" />カート4</MDBSideNavLink>
    //   <MDBSideNavLink to='/cart/5'><MDBIcon icon="shopping-cart" />カート5</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>カート</span>
      </span>}>
      <Menu.Item key={'107'}>
        <MDBSideNavLink to='/cart/1'><MDBIcon icon="shopping-cart" />カート1</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'106'}>
        <MDBSideNavLink to='/cart/2'><MDBIcon icon="shopping-cart" />カート2</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'105'}>
        <MDBSideNavLink to='/cart/3'><MDBIcon icon="shopping-cart" />カート3</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'104'}>
        <MDBSideNavLink to='/cart/4'><MDBIcon icon="shopping-cart" />カート4</MDBSideNavLink>
      </Menu.Item>
      <Menu.Item key={'103'}>
        <MDBSideNavLink to='/cart/5'><MDBIcon icon="shopping-cart" />カート5</MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if(this.state.organization && this.state.organization.maker){
      menuCart = <></>
    }

    if(this.state.organization && this.state.organization.reseller){
      if (this.props.org && this.props.org.maker){
      } else {
        menuCart = <></>
      }
    }

    // if (this.state.user)

    // let menuP4Bill = <MDBSideNavCat
    //   name="P4CLOUD請求書"
    //   id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.administrator && this.props.org &&
    //     <MDBSideNavLink to='/p4bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/p4bills'>請求書一覧</MDBSideNavLink>
    //
    // </MDBSideNavCat>

    let menuP4Bill = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>P4CLOUD請求書</span>
      </span>}>
      { this.state.organization && this.state.organization.administrator && this.props.org && <Menu.Item key={'101'}>
        <MDBSideNavLink to='/p4bills/new'>請求書登録</MDBSideNavLink>
      </Menu.Item> }
      <Menu.Item key={'102'}>
        <MDBSideNavLink to='/p4bills'>請求書一覧</MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if(this.state.organization && this.state.organization.buyer){
      menuP4Bill = <></>
    }

    let menus = <></>
    if (this.props.user.admin) {

    } else {
      menus = <></>
    }

    // let menuStatistic = <MDBSideNavCat
    //     name="データ分析"
    //     id="ja nai"
    // >
    //   <>
    //     <MDBSideNavLink to='/statistic'>
    //       <p>購入データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></p>
    //     </MDBSideNavLink>
    //     {
    //       this.props.userOrg && this.props.userOrg.menuMeasurement && <MDBSideNavLink to='/measurement'>
    //           <span>測定データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></span>
    //         </MDBSideNavLink>
    //     }
    //
    //   </>
    // </MDBSideNavCat>

    let menuStatistic = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>購入データ</span>
      </span>}>
      <Menu.Item key={'1'}>
        <MDBSideNavLink to='/statistic'>
          <span>購入データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></span>
        </MDBSideNavLink>
      </Menu.Item>
      {
        this.props.userOrg && this.props.userOrg.menuMeasurement && <Menu.Item key={'2'}>
            <MDBSideNavLink to='/measurement'>
              <span>測定データ<span style={{ marginLeft: '6em', border: '1px solid', fontSize: '10px', padding: '3px', borderRadius: '5px' }}>ベータ版</span></span>
            </MDBSideNavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>

    // let menuCompany = <MDBSideNavCat
    //     name="企業情報"
    //     id="company-cat"
    // >
    //   <MDBSideNavLink to='/comapnyinfo'>企業情報</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuCompany = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>企業情報</span>
      </span>}>
      <Menu.Item key={'3'}>
        <MDBSideNavLink to='/comapnyinfo'>
          <span>企業情報</span>
        </MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuUserInfo = <MDBSideNavCat
    //     name="取引先情報"
    //     id="user-cat"
    // >
    //   { (() => {
    //     if (this.state.organization && this.state.organization.buyer) {
    //       return <MDBSideNavLink to='/sellerinfo'>取引先情報</MDBSideNavLink>
    //     } else if (this.state.organization && this.state.organization.reseller) {
    //       if (this.props.org && this.props.org.maker ) {
    //         return <MDBSideNavLink to='/makerinfo'>取引先情報</MDBSideNavLink>
    //       } else {
    //         return <MDBSideNavLink to='/buyerinfo'>取引先情報</MDBSideNavLink>
    //       }
    //     } else if (this.state.organization && this.state.organization.maker) {
    //       return <MDBSideNavLink to='/buyerinfo'>取引先情報</MDBSideNavLink>
    //     }
    //   })() }
    // </MDBSideNavCat>

    let menuUserInfo = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>取引先情報</span>
      </span>}>
      { (() => {
        if (this.state.organization && this.state.organization.buyer) {
          return <Menu.Item key={'4'}>
            <MDBSideNavLink to='/sellerinfo'><span>取引先情報</span></MDBSideNavLink>
          </Menu.Item>
        } else if (this.state.organization && this.state.organization.reseller) {
          if (this.props.org && this.props.org.maker ) {
            return <Menu.Item key={'5'}>
              <MDBSideNavLink to='/makerinfo'><span>取引先情報</span></MDBSideNavLink>
            </Menu.Item>
          } else {
            return <Menu.Item key={'6'}>
              <MDBSideNavLink to='/buyerinfo'><span>取引先情報</span></MDBSideNavLink>
            </Menu.Item>
          }
        } else if (this.state.organization && this.state.organization.maker) {
          return <Menu.Item key={'7'}>
            <MDBSideNavLink to='/buyerinfo'><span>取引先情報</span></MDBSideNavLink>
          </Menu.Item>
        }
      })() }
    </Menu.SubMenu>

    // let menuItem = <MDBSideNavCat
    //     name={itemCatName} // 商品購入
    //     id="user-cat"
    // >
    //   <MDBSideNavLink to='/items'>商品一覧</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuItem = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>{itemCatName}</span>
      </span>}>
      <Menu.Item key={'8'}>
        <MDBSideNavLink to='/items'>
          <span>商品一覧</span>
        </MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuFavoriteCart = <MDBSideNavCat
    //     name="お気に入り"
    //     id="user-cat"
    //     icon="star"
    // >
    //   <MDBSideNavLink to='/favorite/cart'>お気に入りセット一覧</MDBSideNavLink>
    // </MDBSideNavCat>

    let menuFavoriteCart = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>お気に入り</span>
      </span>}>
      <Menu.Item key={'9'}>
        <MDBSideNavLink to='/favorite/cart'>
          <span>お気に入りセット一覧</span>
        </MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuChumon = <MDBSideNavCat
    //     name="発注書一覧"
    //     id="user-cat"
    //     className={customClass}
    //     icon={icon}
    // >
    //   <MDBSideNavLink to='/order'>発注書一覧 {this.props.org && this.props.unreadOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOrderWithOrg[this.props.org.id]}</MDBBadge>} {this.props.org && this.props.notShippedOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>
    //
    // if( (this.state.organization && this.state.organization.maker )
    //     || (this.props.org && this.props.org.buyer && this.state.organization && this.state.organization.reseller ) ){
    //   // console.log('header maker')
    //   menuChumon = <MDBSideNavCat
    //       name="注文書一覧"
    //       id="user-cat"
    //       className={customClass}
    //       icon={icon}
    //   >
    //     <MDBSideNavLink to='/order'>注文書一覧 {this.props.org && this.props.unreadOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadOrderWithOrg[this.props.org.id]}</MDBBadge>} {this.props.org && this.props.notShippedOrderWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   </MDBSideNavCat>
    // }

    let menuChumon = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>発注書一覧</span>
        {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
      </span>}>
      <Menu.Item key={'10'}>
        <MDBSideNavLink to='/order' style={{gap: 5}}>
          <span>発注書一覧</span>
          {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
          {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        </MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    if( (this.state.organization && this.state.organization.maker )
        || (this.props.org && this.props.org.buyer && this.state.organization && this.state.organization.reseller ) ){
      // console.log('header maker')
      menuChumon = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>注文書一覧</span>
        {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
        {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
      </span>}>
        <Menu.Item key={'10'}>
          <MDBSideNavLink to='/order'>
            <span>注文書一覧</span>
            {this.props.org && <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={this.props.unreadOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
            {this.props.org && <Badge style={{marginLeft: 5}} color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[this.props.org.id] || 0} showZero={false}/>}
          </MDBSideNavLink>
        </Menu.Item>
      </Menu.SubMenu>
    }

    // let menuEstimates = <MDBSideNavCat
    //     name="工事見積書"
    //     id="user-cat"
    //     icon={iconEstimates}
    //     className={customClassEstimates}
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/estimates'>工事見積書一覧 {this.props.org && this.props.unreadEstimatesWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimatesWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuEstimates = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>工事見積書</span>
      </span>}>

        {this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
            <Menu.Item key={'11'}>
              <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
            </Menu.Item>
        }
        { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
            <Menu.Item key={'12'}>
              <MDBSideNavLink to='/estimates/new'>工事見積書登録</MDBSideNavLink>
            </Menu.Item>
        }
    </Menu.SubMenu>

    // let menuBills = <MDBSideNavCat
    //     icon={iconBill}
    //     name="請求書"
    //     className={customClassBill}
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/bills'>請求書一覧 {this.props.org && this.props.unreadBillWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadBillWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuBills = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>請求書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
          <Menu.Item key={'13'}>
            <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
          <Menu.Item key={'14'}>
            <MDBSideNavLink to='/bills/new'>請求書登録</MDBSideNavLink>
          </Menu.Item>
      }
      <Menu.Item key={'15'}>
        <MDBSideNavLink to='/bills'>請求書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadBillWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>

    // let menuDeliveryNotes = <MDBSideNavCat
    //     icon={iconDeliveryNotes}
    //     name="納品書"
    //     className={customClassDeliveryNotes}
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
    //       <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
    //       <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
    //   }
    //   <MDBSideNavLink to='/deliveryNotes'>納品書一覧 {this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadDeliveryNotesWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuDeliveryNotes = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>納品書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      { this.state.organization && this.state.organization.reseller && this.props.org && this.props.org.buyer &&
          <Menu.Item key={'16'}>
            <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker && this.props.org && this.props.org.reseller &&
          <Menu.Item key={'17'}>
            <MDBSideNavLink to='/deliveryNotes/new'>納品書登録</MDBSideNavLink>
          </Menu.Item>
      }
      <Menu.Item key={'18'}>
        <MDBSideNavLink to='/deliveryNotes'>納品書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadDeliveryNotesWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // let menuEstimates2 = <MDBSideNavCat
    //     name="見積書"
    //     id="user-cat"
    //     icon={iconEstimates2}
    //     className={customClassEstimates2}
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
    //         <MDBSideNavLink to='/estimates2'>見積書一覧 {this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadEstimates2WithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuEstimates2 = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>見積書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      { this.state.organization && this.state.organization.reseller &&
          <>
            { this.props.org && this.props.org.buyer &&
                <Menu.Item key={'19'}>
                  <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
                </Menu.Item>
            }
            <Menu.Item key={'20'}>
              <MDBSideNavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'21'}>
            <MDBSideNavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <>
            <Menu.Item key={'22'}>
              <MDBSideNavLink to='/estimates2/new'>見積書登録</MDBSideNavLink>
            </Menu.Item>
            <Menu.Item key={'23'}>
              <MDBSideNavLink to='/estimates2'>見積書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadEstimates2WithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
            </Menu.Item>
          </>
      }
    </Menu.SubMenu>;

    // let menuSDS = <MDBSideNavCat
    //     name="SDS一覧"
    //     id="user-cat"
    //     icon={iconSDS}
    //     className={customClassSDS}
    // >
    //   <MDBSideNavLink to='/sdsManagement'>SDS管理一覧 {this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuSDS = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>SDS一覧</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      <Menu.Item key={'24'}>
        <MDBSideNavLink to='/sdsManagement'>SDS管理一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.sdsWaitingCountWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // let menuManual = <MDBSideNavCat
    //     name="取扱説明書一覧"
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/manual/new'>取扱説明書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {/* <MDBSideNavLink to='/manual/new'>取扱説明書登録</MDBSideNavLink> */}
    //         <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuManual = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>取扱説明書一覧</span>
      </span>}>
      { this.state.organization && this.state.organization.reseller &&
          <>
            { this.props.org && this.props.org.buyer &&
                <Menu.Item key={'25'}>
                  <MDBSideNavLink to='/manual/new'>取扱説明書登録</MDBSideNavLink>
                </Menu.Item>
            }
            <Menu.Item key={'26'}>
              <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'27'}>
            <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'28'}>
            <MDBSideNavLink to='/manual'>取扱説明書一覧</MDBSideNavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuCatalog = <MDBSideNavCat
    //     name="カタログ一覧"
    //     id="user-cat"
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/catalog/new'>カタログ登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {/* <MDBSideNavLink to='/catalog/new'>カタログ登録</MDBSideNavLink> */}
    //         <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuCatalog = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>カタログ一覧</span>
      </span>}>
      { this.state.organization && this.state.organization.reseller &&
          <>
            { this.props.org && this.props.org.buyer &&
                <Menu.Item key={'29'}>
                  <MDBSideNavLink to='/catalog/new'>カタログ登録</MDBSideNavLink>
                </Menu.Item>
            }
            <Menu.Item key={'30'}>
              <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'31'}>
            <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'32'}>
            <MDBSideNavLink to='/catalog'>カタログ一覧</MDBSideNavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuReport = <MDBSideNavCat
    //     name="報告書"
    //     id="user-cat"
    //     icon={iconReport}
    //     className={customClassReport}
    // >
    //   { this.state.organization && this.state.organization.reseller &&
    //       <>
    //         { this.props.org && this.props.org.buyer &&
    //             <MDBSideNavLink to='/report/new'>報告書登録</MDBSideNavLink>
    //         }
    //         <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    //   { this.state.organization && this.state.organization.buyer &&
    //       <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //   }
    //   { this.state.organization && this.state.organization.maker &&
    //       <>
    //         {<MDBSideNavLink to='/report/new'>報告書登録</MDBSideNavLink>}
    //         <MDBSideNavLink to='/report'>報告書一覧 {this.props.org && this.props.unreadReportWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.unreadReportWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
    //       </>
    //   }
    // </MDBSideNavCat>;

    let menuReport = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>報告書</span>
        <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/>
      </span>}>
      { this.state.organization && this.state.organization.reseller &&
          <>
            { this.props.org && this.props.org.buyer &&
                <Menu.Item key={'33'}>
                  <MDBSideNavLink to='/report/new'>報告書登録</MDBSideNavLink>
                </Menu.Item>
            }
            <Menu.Item key={'34'}>
              <MDBSideNavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
            </Menu.Item>
          </>
      }
      { this.state.organization && this.state.organization.buyer &&
          <Menu.Item key={'35'}>
            <MDBSideNavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
          </Menu.Item>
      }
      { this.state.organization && this.state.organization.maker &&
          <Menu.Item key={'36'}>
            <MDBSideNavLink to='/report'>報告書一覧 <Badge style={{marginLeft: 10}} color={'green'} overflowCount={999} count={(this.props.org && this.props.unreadReportWithOrg[this.props.org.id]) || 0} showZero={false}/></MDBSideNavLink>
          </Menu.Item>
      }
    </Menu.SubMenu>;

    // let menuDeliveryAddress = <MDBSideNavCat
    //     name="納品場所管理"
    //     id="user-cat"
    // >
    //   <MDBSideNavLink to='/deliveryAddress'>納品場所管理(アドレス帳)</MDBSideNavLink>
    // </MDBSideNavCat>;

    let menuDeliveryAddress = <Menu.SubMenu title={<span>
        <UserOutlined />
        <span>納品場所管理</span>
      </span>}>
      <Menu.Item key={37}>
        <MDBSideNavLink to='/deliveryAddress'>納品場所管理(アドレス帳)</MDBSideNavLink>
      </Menu.Item>
    </Menu.SubMenu>;

    // console.log('this.props.user',this.props.user)
    if (this.props.user.admin) {
      // menuStatistic = <></>;
      menuCompany = <></>;
      menuUserInfo = <></>;
      // menuItem = <></>;
      menuFavoriteCart = <></>;
      menuChumon = <></>;
      menuEstimates = <></>;
      menuBills = <></>;
      menuDeliveryNotes = <></>;
      menuEstimates2 = <></>;
      // menuSDS = <></>;
      menuManual = <></>;
      menuCatalog = <></>;
      menuReport = <></>;
      // menuStatistic = <></>;
      menuOtasukeCart = <></>;
      menuCart = <></>;
      menuDeliveryAddress = <></>;
      menuP4Bill = <></>;

      menuSDS = <MDBSideNavCat
          name="SDS管理一覧"
          id="user-cat"
          icon={iconSDS}
          className={customClassSDS}
      >
        <MDBSideNavLink to='/sdsManagement'>SDS管理一覧 {this.props.org && this.props.sdsBuyerWaitingCountWithOrg[this.props.org.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsBuyerWaitingCountWithOrg[this.props.org.id]}</MDBBadge>}</MDBSideNavLink>
      </MDBSideNavCat>;
    }

    let sellerOrganizations = this.state.sellerOrganizations;
    if (this.state.searchOrg) {
      sellerOrganizations = this.state.filteredOrg;
    }

    let buyerOrganizations = this.state.buyerOrganizations;
    if (this.state.searchOrg1) {
      buyerOrganizations = this.state.filteredOrg1;
    }

    let buyersOrganizations = this.state.organization && this.state.organization.buyers;
    if (this.state.searchOrgBuyer || this.state.searchOrgBuyerSeller) {
      buyersOrganizations = this.state.filteredOrgBuyer;
    }

    let sellersOrganizations = this.state.organization && this.state.organization.sellers;
    if (this.state.searchOrgSeller || this.state.searchOrgBuyerSeller) {
      sellersOrganizations = this.state.filteredOrgSeller;
    }

    let customersOrganizations = this.state.organization && this.state.organization.customers;
    if (this.state.searchOrgCustomer) {
      customersOrganizations = this.state.filteredOrgCustomer;
    }

    return (
        <>
          <Sider trigger={null} theme={'dark'} style={siderStyle} width={260} collapsible collapsed={this.props.collapsed}>
            <div className="logo-wrapper">
              <img src="/logo.png" className="img-fluid" alt="HOME" />
            </div>
            <Menu
                theme="dark"
                mode="inline"
                // selectedKeys={[this.state.current]}
                // onClick={(e) => console.log('e', e)}
            >
              { menuStatistic }
              { menuCompany }
              { menuUserInfo }
              { menuItem }
              { menuFavoriteCart }
              { menuChumon }
              { menuEstimates }
              { menuBills }
              { menuDeliveryNotes }
              { menuEstimates2 }
              { menuSDS }
              { menuManual }
              { menuCatalog }
              { menuReport }
              { menuOtasukeCart }
              { menuCart }
              { menuDeliveryAddress }
              <br/>
              { menuP4Bill }
            </Menu>
          </Sider>


          {/*<MDBSideNav*/}
          {/*  triggerOpening={this.state.toggleStateA}*/}
          {/*  breakWidth={this.props.breakWidth}*/}
          {/*  fixed={true}*/}
          {/*  mask="strong"*/}
          {/*>*/}
          {/*  <MDBSideNavNav>*/}
          {/*      <div className="logo-wrapper">*/}
          {/*      <MDBSideNavLink to='/'>*/}
          {/*        <img src="/logo.png" className="img-fluid" alt="HOME" />*/}
          {/*        </MDBSideNavLink>*/}
          {/*      </div>*/}

          {/*    {menuStatistic}*/}

          {/*    {menuCompany}*/}

          {/*    {menuUserInfo}*/}

          {/*    {menuKoutei}*/}

          {/*    {menuItem}*/}

          {/*    {menuFavoriteCart}*/}

          {/*    {menuChumon}*/}

          {/*    {menuEstimates}*/}

          {/*    {menuBills}*/}

          {/*    {menuDeliveryNotes}*/}

          {/*    {menuEstimates2}*/}

          {/*    {menuSDS}*/}

          {/*    {menuManual}*/}

          {/*    {menuCatalog}*/}

          {/*    {menuReport}*/}

          {/*    /!*{menuStatistic}*!/*/}

          {/*    {menuOtasukeCart}*/}

          {/*    {menuCart}*/}

          {/*    {menuDeliveryAddress}*/}

          {/*    <br />*/}
          {/*    {menuP4Bill}*/}

          {/*  </MDBSideNavNav>*/}
          {/*</MDBSideNav>*/}
          {/*<MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>*/}
          {/*  <MDBNavbarNav left>*/}
          {/*    <MDBNavItem>*/}
          {/*      <div*/}
          {/*        onClick={this.handleToggleClickA}*/}
          {/*        key="sideNavToggleA"*/}
          {/*        style={{*/}
          {/*          lineHeight: "32px",*/}
          {/*          marginRight: "1em",*/}
          {/*          verticalAlign: "middle"*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <MDBIcon icon="bars" color="white" size="2x" />*/}
          {/*      </div>*/}
          {/*    </MDBNavItem>*/}
          {/*    <MDBNavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}>*/}
          {/*    {this.state.organization && this.state.organization.buyer && !this.props.location.pathname.startsWith('/measurement') &&*/}
          {/*        <>*/}
          {/*          {this.state.seller_company ? this.state.seller_company.name : ''} {this.state.seller ? this.state.seller.name : ''}*/}
          {/*        </>*/}
          {/*    }*/}
          {/*      {this.state.organization && this.state.organization.buyer && this.props.location.pathname.startsWith('/measurement') &&*/}
          {/*          <>*/}
          {/*            [測定データモード]*/}
          {/*          </>*/}
          {/*      }*/}
          {/*    {this.state.organization && this.state.organization.administrator &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (this.props.org && this.props.org.display_name) ?*/}
          {/*              <>*/}
          {/*                {this.props.org.display_name}*/}
          {/*              </>*/}
          {/*              :*/}
          {/*              <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgCustomer} onChange={this.handleChangeCustomer} onKeyDown={(event) => this.handleKeyDown(event, customersOrganizations || [], [])}/>*/}
          {/*          {this.state.organization && customersOrganizations &&*/}
          {/*              customersOrganizations.map((customers, i) => {*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={customers.organization} active={customers.organization === this.state.activeOrg.organization} data-org={customers.organization} data-name={customers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[customers.organization]}</MDBBadge>} {customers.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[customers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[customers.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*              }*/}
          {/*            )*/}
          {/*          }*/}

          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    {this.state.organization && this.state.organization.reseller &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (this.props.org && this.props.org.display_name && !this.props.location.pathname.startsWith('/measurement')) ?*/}
          {/*              <>*/}
          {/*                {this.props.org.maker ? '[仕入モード]　' : ''}*/}
          {/*                {this.props.org.buyer ? '[販売モード]　' : ''}*/}
          {/*                {this.props.org.display_name}*/}
          {/*              </>*/}
          {/*              :*/}
          {/*                this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgBuyerSeller} onChange={this.handleChangeBuyerSeller} onKeyDown={(event) => this.handleKeyDown(event, buyersOrganizations || [], sellersOrganizations || [])}/>*/}
          {/*          {this.state.organization && buyersOrganizations &&*/}
          {/*              buyersOrganizations.map((buyers, i) => {*/}
          {/*              return <MDBDropdownItem key={i} href="#!" id={buyers.organization} active={buyers.organization === this.state.activeOrg.organization} data-org={buyers.organization} data-name={buyers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                {this.state.organizationUnread[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[buyers.organization]}</MDBBadge>} [売先] {buyers.name}*/}
          {/*                {this.props.notShippedOrderWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*              </MDBDropdownItem>*/}
          {/*            })*/}
          {/*          }*/}
          {/*          /!*<li>*!/*/}
          {/*          /!*  <hr className="dropdown-divider"/>*!/*/}
          {/*          /!*</li>*!/*/}
          {/*          {this.state.organization && sellersOrganizations &&*/}
          {/*              sellersOrganizations.map((seller, i) => {*/}
          {/*                // console.log('seller', seller)*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={seller.organization} active={seller.organization === this.state.activeOrg.organization} data-org={seller.organization} data-name={seller.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[seller.organization]}</MDBBadge>} [仕入先] {seller.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[seller.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[seller.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*            })*/}
          {/*          }*/}

          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    {this.state.organization && this.state.organization.maker &&*/}
          {/*      <>*/}
          {/*      <MDBDropdown>*/}
          {/*        <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            { (!this.props.location.pathname.startsWith('/measurement') && this.props.org && this.props.org.display_name) ?*/}
          {/*              <>{this.props.org.display_name}</>*/}
          {/*              :*/}
          {/*                this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>取引先を選択してください</>*/}
          {/*            }*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*          <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrgBuyer} onChange={this.handleChangeBuyers} onKeyDown={(event) => this.handleKeyDown(event, buyersOrganizations || [], [])}/>*/}
          {/*          {this.state.organization && buyersOrganizations &&*/}
          {/*              buyersOrganizations.map((buyers, i) => {*/}
          {/*                return <MDBDropdownItem key={i} href="#!" id={buyers.organization} active={buyers.organization === this.state.activeOrg.organization} data-org={buyers.organization} data-name={buyers.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                  {this.state.organizationUnread[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.state.organizationUnread[buyers.organization]}</MDBBadge>} {buyers.name}*/}
          {/*                  {this.props.notShippedOrderWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.notShippedOrderWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                  {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyers.organization]}</MDBBadge>}*/}
          {/*                </MDBDropdownItem>*/}
          {/*              }*/}
          {/*            )*/}
          {/*          }*/}
          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*      {this.props.user.admin &&*/}
          {/*        <div style={{display: 'flex'}}>*/}
          {/*          <MDBDropdown>*/}
          {/*            <MDBDropdownToggle nav caret ref={input => this.mdbToggle1 = input}>*/}
          {/*              <div className={'d-none d-md-inline'}>*/}
          {/*                { (!this.props.location.pathname.startsWith('/measurement') && this.props.orgSeller && this.props.orgSeller.display_name) ?*/}
          {/*                    <>{this.props.orgSeller.display_name}</>*/}
          {/*                    :*/}
          {/*                    this.props.location.pathname.startsWith('/measurement') ? <>[測定データモード]</> : <>販売モード側事業所選択</>*/}
          {/*                }*/}
          {/*              </div>*/}
          {/*            </MDBDropdownToggle>*/}
          {/*            <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*              <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrg} onChange={this.handleChange} onKeyDown={(event) => this.handleKeyDown1(event, sellerOrganizations || [])}/>*/}
          {/*              {*/}
          {/*                sellerOrganizations.map((seller, i) => {*/}
          {/*                  return <MDBDropdownItem key={i} href="#!" id={seller.id} active={seller.id === this.state.activeOrg1.id} data-org={seller.organization} data-name={seller.company_name + ' ' + seller.name} onClick={(v) => this.selectSellerOrganization(seller, false)}>*/}
          {/*                    [販売側] {seller.company_name} {seller.name}*/}
          {/*                    {this.props.sdsNormalCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsWarningCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsAlertCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                    {this.props.sdsWaitingCountWithOrg[seller.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[seller.id]}</MDBBadge>}*/}
          {/*                  </MDBDropdownItem>*/}
          {/*                })*/}
          {/*              }*/}
          {/*            </MDBDropdownMenu>*/}
          {/*          </MDBDropdown>*/}
          {/*          {*/}
          {/*              !this.props.location.pathname.startsWith('/measurement') && <MDBDropdown>*/}
          {/*                <MDBDropdownToggle nav caret ref={input => this.mdbToggle = input}>*/}
          {/*                  <div className={'d-none d-md-inline'}>*/}
          {/*                    { (this.props.org && this.props.org.display_name) ?*/}
          {/*                        <>{this.props.org.display_name}</>*/}
          {/*                        :*/}
          {/*                        <>仕入モード側事業所選択</>*/}
          {/*                    }*/}
          {/*                  </div>*/}
          {/*                </MDBDropdownToggle>*/}
          {/*                <MDBDropdownMenu left="true" style={{maxHeight: '80vh', overflow: 'auto'}}>*/}
          {/*                  <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'} value={this.state.searchOrg1} onChange={this.handleChange1} onKeyDown={(event) => this.handleKeyDown(event, buyerOrganizations || [], [])}/>*/}
          {/*                  {*/}
          {/*                    buyerOrganizations.map((buyer, i) => {*/}
          {/*                      return <MDBDropdownItem key={i} href="#!" id={buyer.organization} active={buyer.organization === this.state.activeOrg.organization} data-org={buyer.organization} data-name={buyer.name} onClick={e => {this.setState({activeOrg: {organization: e.currentTarget.dataset.org, name: e.currentTarget.dataset.name}}); this.props.orgSelect(e)}}>*/}
          {/*                        [仕入側] {buyer.name}*/}
          {/*                        {this.props.sdsNormalCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsWarningCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsAlertCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                        {this.props.sdsWaitingCountWithOrg[buyer.organization] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[buyer.organization]}</MDBBadge>}*/}
          {/*                      </MDBDropdownItem>*/}
          {/*                    })*/}
          {/*                  }*/}
          {/*                </MDBDropdownMenu>*/}
          {/*              </MDBDropdown>*/}
          {/*          }*/}

          {/*        </div>*/}
          {/*      }*/}
          {/*    </MDBNavItem>*/}
          {/*  </MDBNavbarNav>*/}
          {/*  <MDBNavbarNav right style={specialCaseNavbarStyles}>*/}
          {/*    <MDBNavItem style={{display: 'flex', alignItems: 'center'}}>*/}
          {/*      <MDBSideNavLink to='/support'>*/}
          {/*        <MDBIcon icon="question-circle" className="d-inline-inline" />{" "}*/}
          {/*        <div className="d-none d-md-inline">Q&A（よくある質問）</div>*/}
          {/*      </MDBSideNavLink>*/}
          {/*    </MDBNavItem>*/}
          {/*    <MDBNavItem>*/}
          {/*      <MDBDropdown style={{color: '#000000'}}>*/}
          {/*        <MDBDropdownToggle nav caret>*/}
          {/*          {this.state.unread > 0 &&*/}
          {/*              <div className="d-none d-md-inline" style={{marginRight: 10}}>*/}
          {/*                未読 <MDBBadge className="ml-2 custom-badge">{this.state.unread}</MDBBadge>*/}
          {/*              </div>*/}
          {/*          }*/}
          {/*          {this.state.unreadNotShipped > 0 &&*/}
          {/*              <div className={"d-none d-md-inline"} style={{marginRight: 10}}>*/}
          {/*                未完 <MDBBadge className="ml-2 custom-badge"*/}
          {/*                             color="danger">{this.state.unreadNotShipped}</MDBBadge>*/}
          {/*              </div>*/}
          {/*          }*/}
          {/*          <div className={"d-none d-md-inline"} style={{marginRight: 10}}>*/}
          {/*            SDS*/}
          {/*            {*/}
          {/*              this.props.user.admin ? <>*/}
          {/*                {*/}
          {/*                    this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]}</MDBBadge>*/}
          {/*                }*/}
          {/*              </> : <>*/}
          {/*                {*/}
          {/*                    this.props.sdsNormalCount > 0 && <MDBBadge className="ml-2 custom-badge" color="primary">{this.props.sdsNormalCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWarningCount > 0 && <MDBBadge className="ml-2 custom-badge" color="warning">{this.props.sdsWarningCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsAlertCount > 0 && <MDBBadge className="ml-2 custom-badge" color="danger">{this.props.sdsAlertCount}</MDBBadge>*/}
          {/*                }*/}
          {/*                {*/}
          {/*                    this.props.sdsWaitingCount > 0 && <MDBBadge className="ml-2 custom-badge">{this.props.sdsWaitingCount}</MDBBadge>*/}
          {/*                }*/}
          {/*              </>*/}
          {/*            }*/}

          {/*          </div>*/}
          {/*          <div className="d-none d-md-inline" >*/}
          {/*            <MDBIcon icon="user" className="d-inline-inline" />{" "}*/}
          {/*             {this.props.user.last_name} {this.props.user.first_name}*/}
          {/*          </div>*/}
          {/*        </MDBDropdownToggle>*/}
          {/*        <MDBDropdownMenu left>*/}
          {/*          /!**/}
          {/*          <MDBDropdownItem href="#!">ユーザー情報</MDBDropdownItem>*/}
          {/*          *!/*/}

          {/*          <MDBDropdownItem header style={{marginTop: 20,marginBottom: 20, textAlign: 'center', weight:'14'}}>{this.props.user.last_name} {this.props.user.first_name}</MDBDropdownItem>*/}
          {/*          /!*<MDBDropdownItem header style={{marginTop: 20,marginBottom: 20, textAlign: 'center', weight:'14'}}>{admin.auth().getUser(this.props.user.uid)</MDBDropdownItem>*!/*/}
          {/*          <MDBDropdownItem header style={{textAlign: 'center'}}>{this.state.company ? this.state.company.name : ''}</MDBDropdownItem>*/}
          {/*          <MDBDropdownItem header style={{textAlign: 'center'}}>{this.state.organization ? this.state.organization.name : ''}</MDBDropdownItem>*/}
          {/*          <MDBDropdownItem href={'#!'} style={{marginTop: 20, marginBottom: 20, fontWeight: 'bold', color: 'black', textAlign: 'center'}}>*/}
          {/*            <MDBSideNavLink to='/emailsettings'>*/}
          {/*              <MDBIcon icon="envelope" style={{color: '#2bbbad', fontSize: 16}} size={'1x'} /> 通知メール受信設定*/}
          {/*            </MDBSideNavLink>*/}
          {/*          </MDBDropdownItem>*/}
          {/*          <MDBDropdownItem href="#!"*/}
          {/*                onClick={() => {*/}
          {/*                  this.props.logout();*/}
          {/*                  // window.location.href="/";*/}
          {/*                }}*/}
          {/*                           style={{border:1, borderStyle:'solid', borderColor: '#D9D9D9',marginTop: 20,marginBottom: 30, textAlign: 'center'}}*/}
          {/*              >ログアウト*/}
          {/*          </MDBDropdownItem>*/}
          {/*          /!*<MDBDropdownItem divider/>*!/*/}
          {/*          /!*<MDBDropdownItem style={{textAlign: 'center'}}>*!/*/}
          {/*          /!*  <MDBIcon icon="question-circle" /> ヘルプ*!/*/}
          {/*          /!*</MDBDropdownItem>*!/*/}
          {/*        </MDBDropdownMenu>*/}
          {/*      </MDBDropdown>*/}
          {/*    </MDBNavItem>*/}
          {/*  </MDBNavbarNav>*/}
          {/*</MDBNavbar>*/}
        </>
    );
  }
}

export default withRouter(DoubleNavigationPage);
